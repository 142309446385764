
import {useState,useEffect} from 'react';

export default function AddressComponent({
	address,AiOutlineDelete,selectedAddress,
	setSelectedAddress,deleteAddress,k,updateSelectedAddressFunc
}) {

	const [selected,setSelected] = useState(false);

	useEffect(()=>{
		if(selectedAddress?.addressId && selectedAddress?.addressId === address?.addressId){
			setSelected(true)
		}else{
			setSelected(false)
		}
	},[selectedAddress]);


	return (
		<div className={`w-full px-2 py-2 border-[1px] border-gray-400 rounded-lg`}>
			<div className="flex justify-between items-center">
				<div className="flex items-center gap-3">
					<label className="container w-min">
					  <input checked={selected} type="checkbox"
					  onClick={(e)=>{
					  	console.log(e.target.checked)
					  	if(e.target.checked){					  		
				  			setSelected(true);
				  			setSelectedAddress(address);
				  			updateSelectedAddressFunc(address);
					  	}else{
					  		setSelectedAddress({});
					  		setSelected(false);
				  			updateSelectedAddressFunc({});
					  	}
					  }} />
					  <div className="checkmark"></div>
					</label>
					<p className="text-gray-800 text-md font-semibold">
						{address?.name}
					</p>
				</div>
				<div onClick={()=>deleteAddress(k)} className="p-1 hover:bg-gray-200/60 cursor-pointer rounded-full">
					<AiOutlineDelete className="h-5 w-5 text-gray-900 hover:text-red-600"/>
				</div>
			</div>
			<div className="my-2 w-[95%] mx-auto mt-3 h-[1px] bg-gray-300"/>
			<p className="text-md text-gray-600 px-1">
				{address?.doorNo}, {address?.address}, {address?.city}, {address?.state}, India.
			</p>
			<p className="text-md text-gray-600 px-1">
				Pincode :- {address?.pincode}
			</p>

		</div>
	)
}