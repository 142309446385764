import { IoMdHelp } from "react-icons/io";
import {useNavigate} from 'react-router-dom';
import {currentProductIdState} from '../atoms/userAtom';
import {useRecoilState} from 'recoil';

export default function OrderCard({
	order,k,toast,toastOptions
}) {
  	const [currentProductId,setCurrentProductId] = useRecoilState(currentProductIdState);
	const navigate = useNavigate();

	return (
		<div className="w-full mb-4 bg-white border-[1px] overflow-hidden border-gray-400 rounded-lg flex flex-col">
			<div className="bg-gray-50 border-b-[1px] border-gray-400 justify-around flex px-4 py-3">
				<div className="flex flex-col gap-1 items-center">
					<p className="text-gray-600 text-sm font-semibold">Order Placed</p>
					<p className="text-gray-600 text-sm font-semibold">{new Date(order?.createdAt).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</p>
				</div>
				<div className="flex flex-col gap-1 items-center">
					<p className="text-gray-600 text-sm font-semibold">Total</p>
					<p className="text-gray-600 text-sm font-semibold">Rs.{order?.price}</p>
				</div>
				<div className="flex flex-col gap-1 items-center">
					<p className="text-gray-600 text-sm font-semibold flex gap-1">Order Id 
					<div onClick={()=>toast("This order ID can assist when asking queries about the order.",toastOptions)} className="p-1 rounded-full cursor-pointer bg-gray-200">
						<IoMdHelp className="h-3 w-3 text-gray-600"/> 
					</div> </p>
					<p className="text-gray-600 text-sm font-semibold">{order?.orderId?.split('_')[order?.orderId?.split("_").length - 1]}</p>
				</div>
			</div>

			<p className="text-gray-900 lg:px-4 my-2 px-3 md:text-lg text-md font-semibold">
				{
					order?.deliveryDate ? `Arriving on ${order?.deliveryDate}`
					:
					'Delivery date will be updated soon.'
				}
			</p>

			<div className="flex flex-col lg:px-4 md:px-2 px-3 gap-4">
				{
					order?.orderedItems?.map((cartItem,j)=>(
						<div className="flex flex-row items-center gap-2">
							<div className="md:w-[20%] w-[30%]">
								<img onClick={()=>{
									navigate(`/Product?productId=${cartItem?.productId}`);
									setCurrentProductId(cartItem?.productId);
								}}  src={cartItem?.images?.[0]} alt=""
								className="aspect-square w-full rounded-lg"/>

							</div>
							<div className="flex flex-col md:w-[80%] w-[70%]">
								<div className="flex items-center justify-between gap-2">
									<p onClick={()=>{
										navigate(`/Product?productId=${cartItem?.productId}`);
										setCurrentProductId(cartItem?.productId);
									}} className="lg:text-lg md:truncate sm:text-md text-sm 
									text-blue-500 hover:text-blue-700 cursor-pointer">
										{cartItem?.title}
									</p>
									<p className="lg:text-lg whitespace-nowrap text-md text-gray-800">
										₹ {Math.round(cartItem?.price - ((cartItem?.offer/100) * cartItem?.price)) * cartItem?.quantity}
									</p>
								</div>
								<p className="text-sm text-gray-800">
									Quantity : <span className="text-gray-600">x{cartItem?.quantity}</span>
								</p>
								<p className="sm:text-md text-sm text-gray-800">
									Sold By : <span className="text-gray-600">Dhana Food Products</span>
								</p>
								<div className="flex items-center gap-2 mt-1">
									<button onClick={()=>{
										navigate(`/Product?productId=${cartItem?.productId}`);
										setCurrentProductId(cartItem?.productId)
									}} className="w-min px-4 py-1 text-xs whitespace-nowrap rounded-md 
									border-[1px] border-gray-400 hover:bg-gray-200">
										View product
									</button>
									<a href={`https://wa.me/8220373777?text=I am having an issue with this order. Order Id = ${order?.orderId}`}>
										<button className="w-min px-4 py-1 text-xs whitespace-nowrap rounded-md 
										border-[1px] border-gray-400 hover:bg-gray-200">
											Contact Support
										</button>
									</a>

								</div>
							</div>	
						</div>
					))	
				}
				
			</div>

			<p className="text-gray-900 lg:px-4 my-2 px-3 mt-2 md:text-lg text-md font-semibold">
				{
					order?.deliveryStatus.toLowerCase() === 'order placed'?
					'Order was placed'
					:
					`Order ${order?.deliveryStatus}`
				}
			</p>

			<div className="md:px-4 px-2 pb-2">
				<div className="w-full bg-white overflow-hidden rounded-full h-2 border-[1px] border-gray-300">
					<div 
					className={`h-full bg-blue-500 ${
						order?.deliveryStatus.toLowerCase() === 'order placed' ? 'w-[10%]' 
						: 
						order?.deliveryStatus.toLowerCase() === 'dispatched' ? 'w-[40%]'
						:
						order?.deliveryStatus.toLowerCase() === 'shipping' ? 'w-[60%]' 
						:
						order?.deliveryStatus.toLowerCase() === 'delivered' ? 'w-[100%]' 
						:
						'w-[0%]'
						} rounded-lg`} />
				</div>

			</div>


		</div>
	)
}