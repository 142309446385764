import { useSwiperSlide } from 'swiper/react';
import React, {useEffect} from 'react';

export default function ProductSubImageComponent({
	image,k,setActiveImage
}) {
	const swiperSlide = useSwiperSlide();
	
	useEffect(()=>{
		if(swiperSlide.isActive){
			setActiveImage(image);
		}
	},[swiperSlide.isActive])

	return (
		<img src={image} 
        className="rounded-lg w-full aspect-square" 
        alt="Cannot load image" />
	)
}