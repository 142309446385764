import Header from "../components/Header";
import SearchBar from "../components/SearchBar";
import { useRecoilState } from "recoil";
import { useState, useEffect } from "react";
import { currentUserState } from "../atoms/userAtom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  fetchProduct,
  updateUserCart,
  updateUserAddress,
  updateSelectedAddress,
  host,
} from "../utils/ApiRoutes";
import {
  AiOutlinePlus,
  AiOutlineMinus,
  AiOutlinePlusCircle,
  AiOutlineDelete,
} from "react-icons/ai";
import { MdShoppingCartCheckout } from "react-icons/md";
import CartItemCard from "../components/CartItemCard";
import AddressComponent from "../components/AddressComponent";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Cart.css";
import logo from "../android-chrome-512x512.png";

export default function Cart() {
  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
  const [cartItems, setCartItems] = useState([]);
  const [selectedCartItems, setSelectedCartItems] = useState([]);
  const [originalSubtotal, setOriginalSubtotal] = useState(0);
  const [discountedSubtotal, setDiscountedSubtotal] = useState(0);
  const [deliveryCharge, setDeliveryCharge] = useState(0); // Delivery Charge
  const [showAddressAdd, setShowAddressAdd] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [pincode, setPincode] = useState("");
  const [address, setAddress] = useState("");
  const [doorNo, setDoorNo] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [addressLoading, setAddressLoading] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [payLoading, setPayLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const toastOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
  };

  const fetchUserCartItems = async (cart, idx, cartArray) => {
    if (cartItems?.length < 1) {
      setLoading(true);
    }
    const { data } = await axios.post(fetchProduct, {
      productId: cart[idx]?.productId,
    });
    if (data?.status && data?.product?.length > 0) {
      cartArray = [
        ...cartArray,
        { ...data?.product[0], quantity: cart[idx]?.quantity },
      ];
      if (idx + 1 >= cart?.length) {
        setCartItems(cartArray);
        setLoading(false);
        if (selectedCartItems.length < 1) setSelectedCartItems(cartArray);
      } else {
        fetchUserCartItems(cart, idx + 1, cartArray);
      }
    } else {
      console.log("Cant fetch a product!");
      if (idx + 1 >= cart?.length) {
        setCartItems(cartArray);
        setLoading(false);
        if (selectedCartItems.length < 1) setSelectedCartItems(cartArray);
      } else {
        fetchUserCartItems(cart, idx + 1, cartArray);
      }
    }
  };

  const calculate = (cart) => {
    let originalSubtotal = 0;
    let discountedSubtotal = 0;

    cart.forEach((item) => {
      const unit = parseInt(item.quantity, 10);
      const price = item.price;
      const offer = item.offer;

      originalSubtotal += unit * price;
      discountedSubtotal += Math.round(
        unit * Math.round(price - (offer / 100) * price)
      );
    });

    return {
      originalSubtotal,
      discountedSubtotal,
    };
  };

  const calculateSubtotal = () => {
    const { originalSubtotal, discountedSubtotal } =
      calculate(selectedCartItems);

    setOriginalSubtotal(originalSubtotal);
    setDiscountedSubtotal(discountedSubtotal);
  };

  useEffect(() => {
    if (selectedCartItems?.length > 0) {
      calculateSubtotal();
    } else {
      setOriginalSubtotal(0);
      setDiscountedSubtotal(0);
    }
  }, [selectedCartItems]);

  useEffect(() => {
    if (!currentUser) {
      navigate("/login");
    } else if (currentUser?.cart?.length > 0) {
      fetchUserCartItems(currentUser?.cart, 0, []);
    } else {
      setLoading(false);
    }
    if (currentUser) {
      setName(currentUser?.name);
      setPhoneNumber(currentUser?.number);
      setSelectedAddress(currentUser?.selectedAddress || {});
    }
  }, [currentUser]);

  const addQuantity = (idx) => {
    let cart = [...cartItems];
    let currentCart = { ...cart[idx], quantity: cart[idx]?.quantity + 1 };
    cart.splice(idx, 1, currentCart);
    setCartItems(cart);
    updateCart(cart);
    let cartIt = [...selectedCartItems];
    let target = cart[idx];
    const index = cartIt?.findIndex((cart) => {
      if (cart?._id === target?._id) {
        return true;
      }
      return false;
    });
    if (index > -1) {
      cartIt?.splice(index, 1, currentCart);
      setSelectedCartItems(cartIt);
    }
  };

  const minusQuantity = (idx) => {
    let cart = [...cartItems];
    if (cart[idx]?.quantity - 1 < 1) {
    } else {
      let currentCart = { ...cart[idx], quantity: cart[idx]?.quantity - 1 };
      cart.splice(idx, 1, currentCart);
      setCartItems(cart);
      updateCart(cart);
      let cartIt = [...selectedCartItems];
      let target = cart[idx];
      const index = cartIt?.findIndex((cart) => {
        if (cart?._id === target?._id) {
          return true;
        }
        return false;
      });
      if (index > -1) {
        cartIt?.splice(index, 1, currentCart);
        setSelectedCartItems(cartIt);
      }
    }
  };

  const updateCart = async (cartIt) => {
    const cart = cartIt?.map((cartItem) => {
      return { quantity: cartItem?.quantity, productId: cartItem?.productId };
    });
    const { data } = await axios.post(updateUserCart, {
      id: currentUser?._id,
      cart,
    });

    if (data?.status) {
      setCurrentUser(data?.user);
    }
  };

  const selectProduct = async (idx) => {
    setSelectedCartItems([...selectedCartItems, cartItems[idx]]);
  };

  const deSelectProduct = async (idx) => {
    let cartIt = [...selectedCartItems];
    let target = cartItems[idx];
    const index = cartIt?.findIndex((cart) => {
      if (cart?._id === target?._id) {
        return true;
      }
      return false;
    });
    if (index > -1) {
      cartIt?.splice(index, 1);
      setSelectedCartItems(cartIt);
    }
  };

  const deleteCartItem = async (idx) => {
    let cart = [...cartItems];
    let target = cart[idx];
    cart.splice(idx, 1);
    setCartItems(cart);
    updateCart(cart);
    let cartIt = [...selectedCartItems];
    const index = cartIt?.findIndex((cart) => {
      if (cart?._id === target?._id) {
        return true;
      }
      return false;
    });
    if (index > -1) {
      cartIt?.splice(index, 1);
      setSelectedCartItems(cartIt);
    }
  };

  const generateId = async () => {
    const idLength = 10;
    let ID = "";
    for (let i = 0; i < idLength; i++) {
      ID += Math.floor(Math.random() * 10);
    }
    return ID;
  };

  const saveAddress = async () => {
    if (
      name?.length > 0 &&
      phoneNumber?.length > 0 &&
      pincode?.length > 0 &&
      doorNo?.length > 0 &&
      address?.length > 0 &&
      city?.length > 0 &&
      state?.length > 0
    ) {
      setAddressLoading(true);
      const addressId = await generateId();
      const newData = {
        name,
        phoneNumber,
        companyName,
        pincode,
        doorNo,
        address,
        city,
        state,
        addressId,
      };
      let userAddresses = [];
      if (currentUser?.address?.length > 0) {
        userAddresses = [...currentUser?.address, newData];
      } else {
        userAddresses = [newData];
      }
      const { data } = await axios.post(updateUserAddress, {
        address: userAddresses,
        selectedAddress: newData,
        id: currentUser?._id,
      });
      if (data?.status) {
        setCurrentUser(data?.user);
        toast.success("Successfully added the address", toastOptions);
        setAddressLoading(false);
        setShowAddressAdd(false);
        setName("");
        setPhoneNumber("");
        setCompanyName("");
        setPincode("");
        setDoorNo("");
        setAddress("");
        setCity("");
        setState("");
      } else {
        toast.error("Cannot add the address!", toastOptions);
        setAddressLoading(true);
      }
    } else {
      toast.warn("Please provide all the required parameters", toastOptions);
    }
  };

  const deleteAddress = async (idx) => {
    let addresses = [...currentUser?.address];
    if (addresses[idx].addressId === selectedAddress?.addressId) {
      setSelectedAddress({});
    }
    addresses.splice(idx, 1);
    const { data } = await axios.post(updateUserAddress, {
      address: addresses,
      selectedAddress: selectedAddress,
      id: currentUser?._id,
    });
    if (data?.status) {
      toast.success("Successfully deleted!");
      setCurrentUser(data?.user);
    } else {
      toast.error("Cannot delete the address!");
    }
  };

  const updateSelectedAddressFunc = async (addr) => {
    const { data } = await axios.post(updateSelectedAddress, {
      id: currentUser?._id,
      selectedAddress: addr,
    });
    if (data?.status) {
      setCurrentUser(data?.user);
    } else {
      toast.error("Cannot Update the selected address!");
    }
  };

  const closeAddAddress = () => {
    setAddressLoading(false);
    setShowAddressAdd(false);
    setName("");
    setPhoneNumber("");
    setCompanyName("");
    setPincode("");
    setDoorNo("");
    setAddress("");
    setCity("");
    setState("");
  };

  const proceedToPay = async () => {
    setPayLoading(!payLoading);
    displayRazorpay();
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // creating a new order
    const result = await axios.post(host + "/createOrder", {
      cartItems: selectedCartItems,
      address: selectedAddress,
    });

    if (!result) {
      toast("Server error. PLease try our later", toastOptions);
      setPayLoading(false);
      return;
    }

    // Getting the order details back
    const { amount, id: order_id, currency } = result.data;
    console.log(result.data);

    const options = {
      key: "rzp_live_qwXtQu0C2yy55h", // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: "Dhana Food Products.",
      description:
        "Products will be delivered within 2-3 days inside Tamil Nadu and 1 week inside India. Delivery date will be updated once order is placed",
      image: { logo },
      order_id: order_id,
      handler: async function (response) {
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
          userId: currentUser?._id,
        };

        const result = await axios.post(host + "/payment/success", data);
        if (result.data.msg === "success") {
          toast("Payment was Successfull", toastOptions);
          setCurrentUser(result?.data?.user);
          setPayLoading(false);

          setTimeout(() => {
            navigate("/Orders");
          }, 3000);
        } else {
          toast(result.data.msg, toastOptions);
          setPayLoading(false);
        }
      },
      prefill: {
        name,
        contact: phoneNumber,
      },
      notes: {
        address: `Name:- ${selectedAddress?.name} \nAddress:- ${selectedAddress?.doorNo}, ${selectedAddress?.address}, ${selectedAddress?.city}, ${selectedAddress?.state}, India. Pincode :- ${selectedAddress?.pincode}`,
      },
      theme: {
        color: "#61dafb",
      },
      modal: {
        ondismiss: function () {
          setPayLoading(false);
          toast.warn("Payment was canceled", toastOptions);
        },
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    console.log("run");
  }

  return (
    <div className="bg-gray-50 overflow-y-auto h-[100vh] w-full">
      <Header />
      <SearchBar />
      <ToastContainer />

      <div className="w-full px-4 py-5 mb-5 flex md:flex-row flex-col gap-7">
        <div className="md:w-[70%] w-full ">
          <div
            id="address-add-container"
            className={`${
              showAddressAdd
                ? "h-auto border-[1px] px-5 py-7 mb-4"
                : "border-[0px] h-[0px]"
            } transition-all duration-200 ease-in-out 
	        		w-full border-gray-300/30 shadow-md bg-white overflow-hidden`}
          >
            <div className="flex items-center gap-3 justify-between">
              <h1 className="md:text-2xl text-lg font-semibold text-black p-0 m-0">
                Add Shipping Address
              </h1>
              <div
                onClick={closeAddAddress}
                className="p-1 hover:bg-blue-200/60 rounded-full cursor-pointer"
              >
                <AiOutlineDelete className="h-6 w-6 text-black hover:text-red-500" />
              </div>
            </div>
            <div className="my-4 h-[1px] w-full bg-gray-300" />
            <div className="flex flex-col gap-4">
              <p className=" text-gray-600 text-md">
                * Name and Phone number are automatically recorded from your
                account
              </p>
              <div className="">
                <label
                  className="block mb-2 text-md font-medium text-gray-700"
                  for="companyName"
                >
                  Company Name (Optional)
                </label>
                <input
                  id="companyName"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  className="block w-full px-4 py-2 text-gray-700 bg-white border-[1px] border-gray-400 rounded-lg 
					            focus:border-blue-400 focus:ring-opacity-40 focus:outline-none focus:ring focus:ring-blue-300"
                  type="text"
                />
              </div>

              <div className="flex items-center mt-1 gap-4 md:flex-row flex-col">
                <div className="md:w-[50%] w-full">
                  <label
                    className="block mb-2 text-md font-medium text-gray-700"
                    for="name"
                  >
                    Name
                  </label>
                  <input
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="block w-full px-4 py-2 text-gray-700 bg-white border-[1px] border-gray-400 rounded-lg 
						            focus:border-blue-400 focus:ring-opacity-40 focus:outline-none focus:ring focus:ring-blue-300"
                    type="text"
                    placeholder="Your full name"
                  />
                </div>
                <div className="md:w-[50%] w-full">
                  <label
                    className="block mb-2 text-md font-medium text-gray-700"
                    for="Phone Number"
                  >
                    Phone Number
                  </label>
                  <input
                    id="Phone Number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className="block w-full px-4 py-2 text-gray-700 bg-white border-[1px] border-gray-400 rounded-lg 
						            focus:border-blue-400 focus:ring-opacity-40 focus:outline-none focus:ring focus:ring-blue-300"
                    type="text"
                    placeholder="Used to assist delivery"
                  />
                </div>
              </div>

              <div className="flex items-center mt-1 gap-4 md:flex-row flex-col">
                <div className="md:w-[50%] w-full">
                  <label
                    className="block mb-2 text-md font-medium text-gray-700"
                    for="Pincode"
                  >
                    Pincode
                  </label>
                  <input
                    id="Pincode"
                    value={pincode}
                    onChange={(e) => setPincode(e.target.value)}
                    className="block w-full px-4 py-2 text-gray-700 bg-white border-[1px] border-gray-400 rounded-lg 
						            focus:border-blue-400 focus:ring-opacity-40 focus:outline-none focus:ring focus:ring-blue-300"
                    type="text"
                  />
                </div>
                <div className="md:w-[50%] w-full">
                  <label
                    className="block mb-2 text-md font-medium text-gray-700"
                    for="Door"
                  >
                    Flat, House no., Building, Company, Apartment
                  </label>
                  <input
                    id="Door"
                    value={doorNo}
                    onChange={(e) => setDoorNo(e.target.value)}
                    className="block w-full px-4 py-2 text-gray-700 bg-white border-[1px] border-gray-400 rounded-lg 
						            focus:border-blue-400 focus:ring-opacity-40 focus:outline-none focus:ring focus:ring-blue-300"
                    type="text"
                  />
                </div>
              </div>

              <div className="mt-1">
                <label
                  className="block mb-2 text-lg font-medium text-gray-700"
                  for="address"
                >
                  Address (Area and Street)
                </label>
                <textarea
                  id="address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  className="block w-full px-4 py-3 text-gray-700 resize-none h-[120px] bg-white border-[1px] border-gray-400 rounded-lg 
					            focus:border-blue-400 focus:ring-opacity-40 focus:outline-none focus:ring focus:ring-blue-300"
                  type="text"
                  placeholder="Main Address"
                />
              </div>

              <div className="flex items-center mt-1 gap-4 md:flex-row flex-col">
                <div className="md:w-[50%] w-full">
                  <label
                    className="block mb-2 text-md font-medium text-gray-700"
                    for="City"
                  >
                    City
                  </label>
                  <input
                    id="City"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    className="block w-full px-4 py-2 text-gray-700 bg-white border-[1px] border-gray-400 rounded-lg 
						            focus:border-blue-400 focus:ring-opacity-40 focus:outline-none focus:ring focus:ring-blue-300"
                    type="text"
                    placeholder="City, District or Town"
                  />
                </div>
                <div className="md:w-[50%] w-full">
                  <label
                    className="block mb-2 text-md font-medium text-gray-700"
                    for="state"
                  >
                    State
                  </label>
                  <input
                    id="state"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    className="block w-full px-4 py-2 text-gray-700 bg-white border-[1px] border-gray-400 rounded-lg 
						            focus:border-blue-400 focus:ring-opacity-40 focus:outline-none focus:ring focus:ring-blue-300"
                    type="text"
                    placeholder="State"
                  />
                </div>
              </div>

              <p className="mt-1 text-gray-600 text-md">
                * For delivery outside India contact our whatsapp number
                provided at top right
              </p>

              <button
                onClick={() => {
                  if (!addressLoading) {
                    saveAddress();
                  }
                }}
                className="px-4 rounded-lg py-2 text-white w-full bg-blue-600 hover:bg-blue-700 cursor-pointer"
              >
                {addressLoading ? <span className="loader4" /> : "Save Address"}
              </button>
            </div>
          </div>
          <div
            className="border-[1px] w-full border-gray-300/30 px-4 
	        		py-6 shadow-md bg-white"
          >
            <h1 className="text-xl font-semibold text-black p-0 m-0">
              Shopping Cart
            </h1>
            <div className="flex items-center justify-between">
              <p
                className="text-sm text-blue-700 hover:text-blue-800 
		        			cursor-pointer p-0 m-0"
              >
                Delete All Items
              </p>
              <p className="text-sm text-gray-600 p-0 m-0">Price</p>
            </div>

            {loading ? (
              <>
                <div className="my-4 h-[1px] w-full bg-gray-300" />
                <div className="w-full flex items-center justify-center h-[200px]">
                  <div class="loader">
                    <svg viewBox="0 0 80 80">
                      <circle id="test" cx="40" cy="40" r="32"></circle>
                    </svg>
                  </div>

                  <div class="loader triangle">
                    <svg viewBox="0 0 86 80">
                      <polygon points="43 8 79 72 7 72"></polygon>
                    </svg>
                  </div>

                  <div class="loader">
                    <svg viewBox="0 0 80 80">
                      <rect x="8" y="8" width="64" height="64"></rect>
                    </svg>
                  </div>
                </div>
              </>
            ) : cartItems?.length > 0 ? (
              cartItems?.map((cartItem, k) => (
                <CartItemCard
                  AiOutlinePlus={AiOutlinePlus}
                  AiOutlineMinus={AiOutlineMinus}
                  cartItem={cartItem}
                  key={k}
                  k={k}
                  addQuantity={addQuantity}
                  minusQuantity={minusQuantity}
                  selectProduct={selectProduct}
                  deSelectProduct={deSelectProduct}
                  deleteCartItem={deleteCartItem}
                  loading={loading}
                />
              ))
            ) : (
              <>
                <div className="my-4 h-[1px] w-full bg-gray-300" />
                <div className="w-full px-4 py-3">
                  <img
                    src="https://cdn.dribbble.com/users/324185/screenshots/15805709/media/98798b7662d8a4b21cb66ad4bd430b48.jpg?resize=400x0"
                    alt=""
                    className="h-[300px] mx-auto"
                  />
                  <p className="text-lg text-center font-semibold text-gray-800">
                    No items in cart{" "}
                    <span
                      onClick={() => {
                        navigate("/");
                      }}
                      className="text-blue-500 cursor-pointer"
                    >
                      Shop now
                    </span>
                  </p>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="md:w-[30%] w-full flex flex-col gap-5">
          <div className="w-full bg-white border-[1px] border-gray-300/30 px-4 py-6 shadow-md">
            <h1 className="text-lg font-semibold text-black">Order Summary</h1>
            <div className="my-3 bg-gray-300 h-[1px]" />

            <div className="flex flex-col gap-2">
              {selectedCartItems?.map((cartItem, k) => (
                <div className="w-full flex items-center gap-2 justify-between">
                  <p className="text-gray-800 text-md">{cartItem?.title}</p>
                  <p className="text-gray-600 text-md">x{cartItem?.quantity}</p>
                </div>
              ))}
            </div>

            {originalSubtotal > 0 && (
              <div className="my-3 bg-gray-300 h-[1px]" />
            )}

            <div className="flex items-center justify-between lg:flex-row md:flex-col flex-row gap-2">
              <h1 className="text-lg font-semibold text-black whitespace-nowrap">
                Subtotal ({cartItems?.length} items) :-
              </h1>
              <div className="flex items-end gap-2">
                {originalSubtotal > 0 && (
                  <span className="text-gray-500 whitespace-nowrap text-md">
                    <strike>₹ {originalSubtotal}</strike>
                  </span>
                )}

                <h1
                  className={`text-lg font-semibold whitespace-nowrap ${
                    originalSubtotal > 0 ? "text-black" : "text-gray-300"
                  }`}
                >
                  ₹ {discountedSubtotal}
                </h1>
              </div>
            </div>

            <div className="flex mt-2 items-center justify-between lg:flex-row md:flex-col flex-row gap-2">
              <h1 className="text-md font-semibold text-black whitespace-nowrap">
                Delivery Charges :-
              </h1>
              <div className="flex items-end gap-2">
                <h1 className="text-md font-semibold whitespace-nowrap text-black">
                  {deliveryCharge === 0 ? "Free" : `₹ {deliveryCharge}`}
                </h1>
              </div>
            </div>

            <div className="my-3 bg-gray-300 h-[1px]" />

            <div
              className={`flex items-center justify-between lg:flex-row 
	        			${
                  originalSubtotal > 0 ? "opacity-1" : "opacity-[30%]"
                } md:flex-col flex-row gap-2`}
            >
              <h1 className="text-lg font-semibold text-black whitespace-nowrap">
                Total Payable :-
              </h1>
              <div className="flex items-end gap-2">
                <h1 className="text-lg font-semibold whitespace-nowrap text-black">
                  ₹ {discountedSubtotal + deliveryCharge}
                </h1>
              </div>
            </div>

            {/* <button onClick={()=>{
	        				if(originalSubtotal > 0){
		        				if(currentUser?.address?.length < 0){
		        					setShowAddressAdd(true)
		        				}else if(!selectedAddress?.addressId){
		        					toast.error("Please select a address");
		        					document.getElementById('address-component').scrollIntoView({ 
		        						behavior: "smooth", block: "end", inline: "nearest" 
		        					});
		        				}else{
		        					proceedToPay();
		        				}
	        				}
	        			}} className={`bg-blue-600 w-full px-5 py-2 rounded-lg mt-3 hover:bg-blue-700 
	        			text-white flex justify-center items-center gap-2 hover:gap-4 transition-all 
	        			duration-200 ease-in-out ${originalSubtotal > 0 ? 'opacity-1 cursor-pointer' : 'cursor-not-allowed opacity-[30%]'} text-md`}>
	        				{
	        					payLoading ? 
	        					<span className="loader3"/>
	        					:
	        					<>
			        				Proceed to buy
			        				<MdShoppingCartCheckout className="h-4 w-4"/> 
	        					</>
	        				}
	        			</button> */}
            {/* Disabled button added here */}
            <button
              onClick={proceedToPay}
              className="bg-blue-600 w-full flex items-center justify-center rounded-lg  text-white sm:px-5 sm:py-2 p-2  mt-3 font-medium"
            >
              Proceed To Pay
            </button>
          </div>

          <div
            id="address-component"
            className="w-full bg-white border-[1px] border-gray-300/30 px-4 py-6 shadow-md"
          >
            <h1 className="text-lg font-semibold text-black">
              Delivery Address
            </h1>

            <div className="my-3 bg-gray-300 h-[1px]" />

            {currentUser?.address?.length > 0 && (
              <div className="flex flex-col gap-3 py-1">
                {currentUser?.address?.map((address, k) => (
                  <AddressComponent
                    address={address}
                    key={k}
                    k={k}
                    AiOutlineDelete={AiOutlineDelete}
                    selectedAddress={selectedAddress}
                    setSelectedAddress={setSelectedAddress}
                    deleteAddress={deleteAddress}
                    updateSelectedAddressFunc={updateSelectedAddressFunc}
                  />
                ))}
              </div>
            )}

            <button
              onClick={() => {
                setShowAddressAdd(!showAddressAdd);
                document
                  .getElementById("address-add-container")
                  .scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                    inline: "nearest",
                  });
              }}
              className="mt-3 px-4 py-2 rounded-lg bg-blue-600 hover:bg-blue-700 
	        			cursor-pointer w-full text-white flex items-center gap-2 hover:gap-4 transition-all 
	        			duration-200 ease-in-out justify-center"
            >
              Add address
              <AiOutlinePlusCircle className="h-4 w-4" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
