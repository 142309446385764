
import {HiOutlineArrowSmRight,HiOutlineArrowSmLeft} from 'react-icons/hi';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { PiShapes } from "react-icons/pi";
import {useState,useEffect} from 'react';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import CategoryCard from './CategoryCard'
import {selectedCategoriesState} from '../atoms/userAtom';
import {useRecoilState} from 'recoil';
import {fetchCategories,getTotalCategoryProducts} from '../utils/ApiRoutes';
import {useRef} from 'react';
import {useNavigate} from 'react-router-dom';

import 'swiper/css';
import 'swiper/css/pagination';


export default function Categories() {
	const swiperRef = useRef();
	const navigate = useNavigate();
	const [selectedCategories,setSelectedCategories] = useRecoilState(selectedCategoriesState);
	
	const [categories,setCategories] = useState([]);

	const fetchCategoriesFunc = async() => {
		const {data} = await axios.get(fetchCategories);
		if(data?.status){
			setCategories(data?.category)
		}
	}

	useEffect(()=>{
		fetchCategoriesFunc()
	},[])

	return (
		<main className="max-w-6xl mx-auto xl:px-0 mt-5 mb-3 px-5">
			<div className="flex items-center gap-2 justify-between">
				<h1 className="text-black text-xl font-semibold">
					Shop by Categories
				</h1>
				<div className="md:flex hidden items-center md:gap-5 gap-4">
					<div onClick={()=>swiperRef.current.slidePrev()} className="p-1 bg-[#59b512] rounded-full cursor-pointer hover:scale-[105%] transition-all 
					duration-200 ease-in-out flex items-center justify-center">
						<HiOutlineArrowSmLeft className="h-5 w-5 text-white"/>
					</div>
					<div onClick={()=>swiperRef.current.slideNext()} className="p-1 bg-[#59b512] rounded-full cursor-pointer hover:scale-[105%] transition-all 
					duration-200 ease-in-out flex items-center justify-center">
						<HiOutlineArrowSmRight className="h-5 w-5 text-white"/>
					</div>
				</div>
			</div>

			<div className="md:block hidden">
			 <Swiper
		        slidesPerView={7}
		        spaceBetween={12}
		        className="mt-5"
		        onSwiper={(swiper) => {
		          swiperRef.current = swiper;
		        }}
		      >
		      	{
		      		categories?.map((category,j)=>(
		      			<SwiperSlide>
		      				<CategoryCard category={category} key={j} j={j}
		      				PiShapes={PiShapes} axios={axios} setSelectedCategories={setSelectedCategories}
		      				navigate={navigate} getTotalCategoryProducts={getTotalCategoryProducts}
		      				/>
		      				
		      			</SwiperSlide>
		      		))
		      	}
		      </Swiper>
		  	</div>
		  	<div className="md:hidden block grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 gap-3 pt-5 grid-cols-2">
		  		{
		      		categories?.map((category,j)=>(
	      				<div key={j} key={j} onClick={()=>{
							setSelectedCategories([category?.categoryId]);
							navigate("/Products");
						}} className="flex border-[4px] border-gray-300/50 hover:border-gray-300/80 
	      				cursor-pointer transition-all group duration-200 ease-in-out rounded-lg 
	      				flex-col p-2 bg-gray-50/50 hover:bg-gray-50 sm:shadow-xl shadow-md">
		      				<div className="overflow-hidden rounded-lg">
		      					<img src={category?.image} alt=""
		      					className="w-full group-hover:scale-[110%] transition-all duration-200 ease-in-out 
				      			rounded-lg object-cover aspect-square"
		      					/>
		      				</div>
	      					<p className="text-gray-800 mt-2 text-md">
	      						{category?.name}
	      					</p>
	      					<p className="text-gray-800 flex mt-2 items-center gap-1 text-md">
	      						<PiShapes className="h-5 w-5 text-gray-800" />
	      						{category?.products} products
	      					</p>
	      				</div>
		      		))
		      	}

		  	</div>

		  	

		</main>
	)
}