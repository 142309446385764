

import Header from '../components/Header';
import SearchBar from '../components/SearchBar';
import ProductsCategoryCard from '../components/ProductsCategoryCard';
import ProductCardHover from '../components/ProductCardHover'; 
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./Products.css";
import {useRecoilState} from 'recoil';
import {useState,useEffect} from 'react';
import {currentUserState,selectedCategoriesState,searchQueryState} from '../atoms/userAtom';
import {useNavigate,useLocation} from 'react-router-dom';
import axios from 'axios';
import {fetchCategories,fetchProducts,login} from '../utils/ApiRoutes';
import './Cart.css';

export default function Products() {
	const navigate = useNavigate();
  	const location = useLocation();
	const [currentUser,setCurrentUser] = useRecoilState(currentUserState);
	const [loading,setLoading] = useState(true);
	const [allCategories,setAllCategories] = useState([]);
	const [selectedCategories,setSelectedCategories] = useRecoilState(selectedCategoriesState);
	const [allProducts,setAllProducts] = useState([]);
	const [bestSellingProducts,setBestSellingProducts] = useState([]);
	const [categoryProducts,setCategoryProducts] = useState([]);
	const [favoriteProducts,setFavoriteProducts] = useState([]);
	const [searchResults,setSearchResults] = useState([]);
	const [searchQuery,setSearchQuery] = useRecoilState(searchQueryState);
	const [favorites,setFavorites] = useState(false);
	const [bestSelling,setBestSelling] = useState(false);

	const fetchAllCategories = async() => {
		setLoading(true);
		const {data} = await axios.get(fetchCategories);
		if(data?.status){
			setAllCategories(data?.category)
		}
	}

	useEffect(()=>{
		if(selectedCategories?.length > 0){
			let filteredProducts = allProducts?.filter((product)=>{
				for(let i = 0;i < product?.categoryId?.length; i++){
					if(selectedCategories?.includes(product?.categoryId[i])) return true
					return false
				}
			})
			setCategoryProducts(filteredProducts);
		}
		if(currentUser?.favProducts?.length > 0 && favoriteProducts?.length < 1){
			let filteredProducts = allProducts?.filter((product)=>{
				if(currentUser?.favProducts?.includes(product?.productId)) return true
				return false
			})
			setFavoriteProducts(filteredProducts);
		}
	},[selectedCategories,allCategories,allProducts]);

	useEffect(()=>{
		const params = new URLSearchParams(location.search);
        const param1 = params.get('bestSelling');
        const param2 = params.get('showFavorites');
        if(param1){
        	setBestSelling(true);
        	setFavorites(false);
        	setSelectedCategories([]);
        }else if(param2) {
        	setBestSelling(false);
        	setFavorites(true);
        	setSelectedCategories([])
        } else {
        	setBestSelling(false);
        	setFavorites(false);
        }
	},[location.search])

	const fetchAllProducts = async() => {
		setLoading(true);
		const {data} = await axios.post(fetchProducts,{bestSelling:false});
		const response = await axios.post(fetchProducts,{bestSelling:true});
		if(data.status){
			setBestSellingProducts(response?.data?.product);
			setAllProducts([...response?.data?.product,...data?.product]);
			setLoading(false);
		}
	}

	useEffect(()=>{
		if(searchQuery?.length > 0) {
			let filteredProducts = allProducts?.filter((product)=>{
				if(product?.title?.toLowerCase().includes(searchQuery.toLowerCase())) return true
				return false
			})
			setSearchResults(filteredProducts);
		} 
	},[searchQuery,allProducts])

	const checkAuth = async() => {
	    if(!currentUser && localStorage.getItem("Dhana-Foods-Auth")){
	      const localData = localStorage.getItem("Dhana-Foods-Auth");
	      const parsedData = JSON.parse(localData);
	      const {data} = await axios.post(login,parsedData);
	      if(data?.status) {
	        setCurrentUser(data?.user);
	      }
	    }
	}

	useEffect(()=>{
		fetchAllCategories();
		fetchAllProducts();
		checkAuth();
	},[])
	

	return(
	<main className="bg-gray-50 h-[100vh]">
		<main className="bg-gray-50 overflow-y-auto h-full w-full">
	        <Header />
        	<SearchBar notSticky='true' />
    	  	<ToastContainer />

    	  	<div className="sm:hidden block top-0 px-4 py-3 bg-gray-200 rounded-b-xl shadow-xl shadow-[#59b512]/30 
	  		flex flex-col gap-2">
	  			<div className="flex items-center gap-2 w-full">
					<div className={`rounded-lg ${bestSelling ? 'apply-instagram-gradient' : 'bg-white text-gray-900'} 
					border-[1px] px-2 py-1 w-[50%] cursor-pointer border-gray-400 transition-all duration-300 ease-in-out 
					items-center`}>
						<p onClick={()=>{
							setSelectedCategories([]);
							setFavorites(false);
							setBestSelling(!bestSelling);
							setSearchQuery('');
						}} className="text-md font-semibold transition-all duration-300 ease-in-out text-center">
							Best Selling
						</p>
					</div>
					{
						currentUser?.favProducts?.length > 0 &&
						<div className={`rounded-lg ${favorites ? 'apply-instagram-gradient' : 'bg-white text-gray-900'} 
						border-[1px] px-2 py-1 w-[50%] cursor-pointer border-gray-400 transition-all duration-300 ease-in-out`}>
							<p onClick={()=>{
								setSelectedCategories([]);
								setBestSelling(false);
								setSearchQuery('');
								setFavorites(!favorites);
							}} className="text-md font-semibold transition-all duration-300 ease-in-out text-center">
								My Favorites
							</p>
						</div>
					}

	  			</div>
				<div className="flex flex-col mt-1 mb-1">
					<p className="text-md font-semibold text-gray-700">Categories</p>
					<div className="my-2 mb-1 h-[1px] w-full bg-gray-400"/>
					<div className="flex flex-row flex-wrap gap-1">
						{
							allCategories?.map((category,k)=>(
								<ProductsCategoryCard category={category}
								key={k} k={k} selectedCategories={selectedCategories} 
								setSelectedCategories={setSelectedCategories} 
								setBestSelling={setBestSelling} setFavorites={setFavorites}
								setSearchQuery={setSearchQuery}
								/>
								
							))
						}
					</div>
				</div>
	  		</div>

    	  	<div className="flex w-full relative">
    	  		<div className="h-full sm:block sticky hidden top-0 px-4 py-3 bg-gray-200 rounded-b-xl shadow-xl shadow-[#59b512]/30 
    	  		flex flex-col gap-2">
    	  			<div className={`w-full cursor-pointer transition-all duration-300 ease-in-out md:mt-0 mt-4 md:px-8 px-5`}>
						<img onClick={()=>{navigate('/')}} src="https://ik.imagekit.io/d3kzbpbila/thejashari_HkNJJQp9gd" alt="" 
						className="w-[130px] mx-auto rounded-lg"/>
					</div>
					<div className={`rounded-lg ${bestSelling ? 'apply-instagram-gradient' : 'bg-white text-gray-900'} 
					border-[1px] px-2 py-1 cursor-pointer mt-3 border-gray-400 transition-all duration-300 ease-in-out`}>
						<p onClick={()=>{
							setSelectedCategories([]);
							setSearchQuery('');
							setFavorites(false);
							setBestSelling(!bestSelling);
						}} className="text-md font-semibold transition-all duration-300 ease-in-out text-center">
							Best Selling
						</p>
					</div>
					{
						currentUser?.favProducts?.length > 0 &&
						<div className={`rounded-lg ${favorites ? 'apply-instagram-gradient' : 'bg-white text-gray-900'} 
						border-[1px] px-2 py-1 cursor-pointer mt-2 border-gray-400 transition-all duration-300 ease-in-out`}>
							<p onClick={()=>{
								setSelectedCategories([]);
								setBestSelling(false);
								setSearchQuery('');
								setFavorites(!favorites);
							}} className="text-md font-semibold transition-all duration-300 ease-in-out text-center">
								My Favorites
							</p>
						</div>
					}
					<div className="flex flex-col mt-1 mb-1">
						<p className="text-md font-semibold text-gray-700">Categories</p>
						<div className="my-2 mb-1 h-[1px] w-full bg-gray-400"/>
						<div className="flex flex-col gap-1">
							{
								allCategories?.map((category,k)=>(
									<ProductsCategoryCard category={category}
									key={k} k={k} selectedCategories={selectedCategories} 
									setSelectedCategories={setSelectedCategories} 
									setBestSelling={setBestSelling} setFavorites={setFavorites}
									setSearchQuery={setSearchQuery}
									/>
									
								))
							}
						</div>
					</div>
    	  		</div>
    	  		<div className="h-full w-full md:px-5 px-2 py-5 flex flex-col">
    	  			<h1 className="text-xl font-semibold text-gray-900">
    	  				{searchQuery?.length > 0 ? 'Search Results' : bestSelling ? 'Best Selling Products' : favorites ? 'Your Favorites' : selectedCategories?.length > 0 ? 'Category Products' : 'All Products'}
    	  			</h1>
    	  			<div className="w-full h-[1px] bg-gray-300 my-3"/>
    	  			<div className="grid relative xl:grid-cols-5 lg:grid-cols-5 gap-4 mt-2 md:grid-cols-3 grid-cols-2">
    	  				{
    	  					loading &&
	    	  				<div className="absolute top-0 z-50 left-0 bg-gray-50 h-full w-full">
	    	  					<div className="w-full flex items-center justify-center h-[200px]">
		    	  					<div class="loader">
									    <svg viewBox="0 0 80 80">
									        <circle id="test" cx="40" cy="40" r="32"></circle>
									    </svg>
									</div>

									<div class="loader triangle">
									    <svg viewBox="0 0 86 80">
									        <polygon points="43 8 79 72 7 72"></polygon>
									    </svg>
									</div>

									<div class="loader">
									    <svg viewBox="0 0 80 80">
									        <rect x="8" y="8" width="64" height="64"></rect>
									    </svg>
									</div>
		    	  				</div>
	    	  				</div>
    	  				}
    	  				{
    	  					searchQuery?.length > 0 ?
    	  					searchResults?.map((product,i)=>(
	    	  						<ProductCardHover product={product} key={i} i={i}
	    	  						currentUser={currentUser} setCurrentUser={setCurrentUser}
	    	  						/>
    	  					))
    	  					:
    	  					bestSelling ?
    	  					bestSellingProducts?.map((product,i)=>(
	    	  						<ProductCardHover product={product} key={i} i={i}
	    	  						currentUser={currentUser} setCurrentUser={setCurrentUser}
	    	  						/>
    	  					))
    	  					:
    	  					favorites ? 
    	  					favoriteProducts?.map((product,i)=>(
	    	  						<ProductCardHover product={product} key={i} i={i}
	    	  						currentUser={currentUser} setCurrentUser={setCurrentUser}
	    	  						/>
    	  					))
    	  					:    	  					
    	  					selectedCategories?.length > 0 ?
    	  					categoryProducts?.map((product,i)=>(
	    	  						<ProductCardHover product={product} key={i} i={i}
	    	  						currentUser={currentUser} setCurrentUser={setCurrentUser}
	    	  						/>
    	  					))
    	  					:
    	  					allProducts?.map((product,i)=>(
	    	  						<ProductCardHover product={product} key={i} i={i}
	    	  						currentUser={currentUser} setCurrentUser={setCurrentUser}
	    	  						/>
    	  					))
    	  				}
    	  				

    	  			</div>


    	  		</div>
    	  	</div>

    	</main>
    </main>
	)
}