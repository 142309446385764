import {useState,useEffect} from 'react';

export default function CartItemCard({
	AiOutlinePlus,AiOutlineMinus,cartItem,
	addQuantity,minusQuantity,k,selectProduct,
	deSelectProduct,deleteCartItem,loading
}) {
	const [selected,setSelected] = useState(true);
	

	return (
		<>
			<div className="my-4 h-[1px] w-full bg-gray-300"/>
    			<div key={k} className="w-full sm:px-4 py-2 sm:gap-4 gap-2 flex items-center ">
    				<div className="w-[5%] flex items-center justify-center">
    					<input checked={selected} type="checkbox" 
    					onClick={(e)=>{
    						if(e.target.checked){
    							selectProduct(k)
    							setSelected(true);
    						}else{
    							deSelectProduct(k)
    							setSelected(false);
    						}
    					}}
    					/>
    				</div>
    				<div className="w-[20%] relative">
    					{
    						!selected &&
    						<div className="z-20 absolute top-0 left-0 bg-white/70 h-full w-full"/>
    					}
    					<img src={cartItem?.images?.[0]} alt=""
    					className="aspect-square w-full rounded-md"
    					/>
    				</div>
    				<div className="flex flex-col relative w-[75%]">
    					{
    						!selected &&
    						<div className="z-20 absolute top-0 left-0 bg-white/70 h-full w-full"/>
    					}
    					<div className="flex items-center justify-between w-full">
        					<h1 className="lg:text-xl md:text-lg text-md font-semibold text-black">
        						{cartItem?.title}
        					</h1>
        					<div className="flex items-end gap-2">
	        					<h1 className="text-xl whitespace-nowrap font-semibold text-black">
	        						₹ {Math.round(Number(cartItem?.price)-Number((cartItem?.offer/100) * cartItem?.price))} 
	        					</h1>
        						<span className="text-gray-500  whitespace-nowrap text-md">
        							<strike>₹ {Number(cartItem?.price)}</strike>
        						</span>
        					</div>
    					</div>
    					<p className="text-green-600 text-sm">In Stock</p>
    					
    					<div className="flex items-end gap-1 mt-2">
        					<p className="text-black font-semibold text-md whitespace-nowrap">Unit : </p>
        					<p className="text-gray-700">{cartItem.unit} Kg</p> 
    					</div>

    					<div className="flex sm:items-end gap-1 mt-1">
        					<p className="text-black font-semibold text-md whitespace-nowrap">Description : </p>
        					<p className="text-gray-700 text-sm">{cartItem.description?.length > 30 ? `${cartItem?.description?.substring(0,27)}...` : cartItem?.description}</p> 
    					</div>
    					<div className="flex items-center gap-2">
	    					<div className="w-min px-1 py-2">
								<div className="bg-white border-gray-500/40 border-[1px] rounded-lg gap-2 
								flex items-center justify-between px-2 py-1">
									<div 
									onClick={()=>{if(!loading) addQuantity(k)}}
									className={`p-[2px] rounded-full ${!loading ? 'opacity-1 cursor-pointer' : 'opacity-[40%]'} hover:bg-gray-200/40 transition-all duration-200 ease-in-out`}>
										<AiOutlinePlus className="select-none h-5 w-5 text-[#59b512]"/>
									</div>
									<p id="quantity-Coco soap scented" className="text-md mx-5 text-gray-600 select-none">{cartItem?.quantity}</p>
									<div 
									onClick={()=>{if(!loading) minusQuantity(k)}}
									className={`p-[2px] rounded-full ${!loading ? 'opacity-1 cursor-pointer' : 'opacity-[40%]'} hover:bg-gray-200/40 transition-all duration-200 ease-in-out`}>
										<AiOutlineMinus className="select-none h-5 w-5 text-[#59b512]"/>
									</div>
								</div>
							</div>
							<div className="border-gray-300 border-l-[1px] px-2">
								<p onClick={()=>deleteCartItem(k)}
								className="text-red-500 hover:text-red-700 cursor-pointer">
									Delete Item
								</p>
							</div>
						</div>

    				</div>


    			</div>
		</>
	)
}