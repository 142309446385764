import Header from '../components/Header';
import SearchBar from '../components/SearchBar';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useRecoilState} from 'recoil';
import {useState,useEffect} from 'react';
import {currentUserState} from '../atoms/userAtom';
import {uploadImageRoute,updateUserDetails} from '../utils/ApiRoutes';
import axios from 'axios';
import {useNavigate} from 'react-router-dom'
import { LuUpload } from "react-icons/lu";
import { MdDeleteOutline } from "react-icons/md";
import './Settings.css';

export default function Settings() {
	const [currentUser,setCurrentUser] = useRecoilState(currentUserState);
	const [profile,setProfile] = useState('');
	const [name,setName] = useState('');
	const [number,setNumber] = useState('');
	const [email,setEmail] = useState('');
	const [path,setPath] = useState('');
	const [url,setUrl] = useState('');
	const [loading,setLoading] = useState(false);
	const navigate = useNavigate();

	const uploadImage = async(url) => {
		setLoading(true);
		console.log(url)
		const {data} = await axios.post(uploadImageRoute,{
			file:url,
			fileName:name+' - Profile Image'
		})
		if(data.status){
			setProfile(data?.imageUrl);
			setUrl('');
			setPath('');
			setLoading(false);
		}
	}

	useEffect(()=>{
		if(!currentUser){
			navigate("/login")
		}else{
			setProfile(currentUser?.image);
			setName(currentUser?.name);
			setEmail(currentUser?.email);
			setNumber(currentUser?.number);
		}
	},[]);

	const updateUser = async() => {
		const {data} = await axios.post(updateUserDetails,{
			image:profile,
			name,
			email,id:currentUser?._id
		})
		if(data?.status){
			setCurrentUser(data?.user);
			navigate('/');
		}else{
			alert("Cannot update your details");
		}
	}

	const urlSetter = () => {
		const image_input = document.querySelector('#file1');
		const reader = new FileReader();
		reader.addEventListener('load',()=>{
			let uploaded_image = reader.result;
			setUrl(uploaded_image);	
		});
		if(image_input){
			reader.readAsDataURL(image_input.files[0]);
		}
	}

	useEffect(()=>{
		if(url) uploadImage(url)
	},[url])


	return (
		<main className="bg-gray-50 overflow-y-auto h-[100vh] w-full">
	        <Header />
        	<SearchBar />
    	  	<ToastContainer />

    	  	<div className="w-full sm:px-7 px-3 py-5 ">
    	  		<h1 className="text-2xl font-semibold text-gray-900">Profile</h1>
    	  		<p className="text-md text-gray-600">Update your profile photo and personal details.</p>
    	  		<div className="w-full h-[1px] bg-gray-300 my-3"/>
    	  		<div className="flex mt-2 gap-5">
    	  			<div>
	    	  			<div className="relative md:h-[150px] h-[120px] aspect-square">
	    	  				{
	    	  					loading &&
		    	  				<div className="absolute z-50 top-0 left-0 bg-white/40 flex items-center 
		    	  				justify-center rounded-xl h-full w-full">
									<div class="loaderimage"></div>
		    	  				</div>
	    	  				}
		    	  			<img src={profile} alt=""
		    	  			className="rounded-xl h-full w-full"
		    	  			/>
	    	  			</div>
	    	  		</div>

    	  			<div className="flex flex-col gap-2 w-full">
    	  				<p className="text-lg font-semibold text-black">
    	  					Profile Picture
    	  				</p>
    	  				<div className="flex mt-2 md:flex-row w-full flex-col items-center gap-2">
    	  					<input type="file" id="file1" accept="image/*"
    	  					value={path} 
    	  					onChange={(e)=>{
								if(e.target.value){
									setPath(e.target.value);
									urlSetter();
								}
							}}
							hidden
							/>
    	  					<button onClick={()=>document.getElementById('file1').click()} className="bg-blue-500 whitespace-nowrap flex items-center justify-center gap-2 sm:w-auto 
    	  					w-full text-white px-4 py-2 rounded-lg">
    	  						<LuUpload className="h-4 w-4"/>
    	  						Upload Image
    	  					</button>
    	  					<button onClick={()=>setProfile('https://ik.imagekit.io/d3kzbpbila/thejashari_vTeNJ0LMT')} 
    	  					className={`bg-white whitespace-nowrap flex items-center justify-center gap-2 sm:w-auto 
    	  					w-full border-[1px] border-gray-400 
    	  					text-black px-4 py-2 ${profile !== 'https://ik.imagekit.io/d3kzbpbila/thejashari_vTeNJ0LMT' ? 'opacity-1' : 'opacity-[50%]'} rounded-lg`}>
    	  						<MdDeleteOutline className="h-5 w-5"/>
    	  						Remove
    	  					</button>
    	  				</div>
    	  				<p className="text-md text-gray-600">
    	  					We support PNGs, JPEGs, JPGs and GIFs under 10MB.
    	  				</p>
    	  			</div>


    	  		</div>

    	  		<div className="flex flex-col gap-5 px-1 py-2">
    	  			<div className="flex items-center mt-4 gap-4 md:flex-row flex-col">
			        	<div className="md:w-[50%] w-full">
			            	<label className="block mb-2 text-lg font-medium text-gray-700" 
				            for="name">Full Name</label>
				            <input id="name" value={name} onChange={(e)=>setName(e.target.value)}
				            className="block w-full px-4 py-2 text-gray-700 bg-white border-[1px] border-gray-400 rounded-lg 
				            focus:border-blue-400 focus:ring-opacity-40 focus:outline-none focus:ring focus:ring-blue-300" 
				            type="text" />
				        </div>
				        <div className="md:w-[50%] w-full">
			            	<label className="block mb-2 text-lg font-medium text-gray-700" 
				            for="email">Email</label>
				            <input placeholder="Enter your email" id="email" value={email} onChange={(e)=>setEmail(e.target.value)}
				            className="block w-full px-4 py-2 text-gray-700 bg-white border-[1px] border-gray-400 rounded-lg 
				            focus:border-blue-400 focus:ring-opacity-40 focus:outline-none focus:ring focus:ring-blue-300" 
				            type="text" />
				        </div>
			        </div>
			        <div className="md:w-[50%] w-full">
		            	<label className="block mb-2 text-lg font-medium text-gray-700" 
			            for="number">Phone Number</label>
			            <input disabled id="number" value={number}
			            className="block w-full px-4 py-2 text-gray-400 bg-white border-[1px] border-gray-400 rounded-lg 
			            focus:border-blue-400 focus:ring-opacity-40 focus:outline-none focus:ring focus:ring-blue-300" 
			            type="text" />
			        </div>

    	  		</div>


	  			<div className="flex mt-4 items-center gap-2">
	  				<button onClick={()=>updateUser()} className="px-5 py-2 rounded-lg bg-indigo-500 text-white">
	  					Save Changes
	  				</button>
	  				<button onClick={()=>{
	  					navigate('/');
	  				}} className="px-5 py-2 rounded-lg bg-white border-[1px] border-gray-400 text-black">
	  					Cancel
	  				</button>
	  			</div>


    	  	</div>

    	</main>
	)
}