import {motion} from 'framer-motion';
import {useState,useEffect} from 'react';
import {AiOutlinePlus,AiOutlineMinus} from 'react-icons/ai'; 
import {updateUserCart} from '../utils/ApiRoutes';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import {useRecoilState} from 'recoil';
import {currentProductIdState} from '../atoms/userAtom';
import './ProductCardHover.css';
import { TiTick } from "react-icons/ti";

export default function ProductCardHover({
	product,i,currentUser,setCurrentUser
}) {
	const [discounted,setDiscounted] = useState('');
	const [quantity,setQuantity] = useState(1);
  	const [currentProductId,setCurrentProductId] = useRecoilState(currentProductIdState);
	const [loading,setLoading] = useState(false);
	const [animationShow,setAnimationShow] = useState(false);
	const navigation = useNavigate();

	useEffect(()=>{
		if(product.offer > 0){
			const discountAmount = (product?.offer / 100) * product?.price;
		  	const discountedPrice = product?.price - discountAmount;
		  	setDiscounted(Math.round(discountedPrice));
		}
	},[product])
	
	const addQuantity = () => {
		setQuantity(quantity + 1);
	}

	const minusQuantity = () => {
		if(quantity > 1){
			setQuantity(quantity - 1)
		}
	}

	const startButtonAnimation = () => {
		setLoading(false);
		setAnimationShow(true);
		setTimeout(()=>{
			setAnimationShow(false);
		},1500)
	}

	const addToCart = async() => {
		setLoading(true);
		let cart = [...currentUser?.cart];
		let index = cart?.findIndex((car)=>{
			if(car?.productId === product?.productId){
				return true
			}
			return false
		})
		if(index > -1){
			let target = cart[index];
			const updatedCartItem = {
				productId:target.productId,
				quantity:target.quantity + quantity
			}
			cart.splice(index,1,updatedCartItem);
			const {data} = await axios.post(updateUserCart,{
				id:currentUser?._id,
				cart
			})

			if(data?.status){
				setCurrentUser(data?.user);
				startButtonAnimation();
			}
		}else{
			let userCart = [...currentUser?.cart,{
				productId:product?.productId,
				quantity
			}];
			const {data} = await axios.post(updateUserCart,{
				id:currentUser?._id,
				cart:userCart
			})

			if(data?.status){
				setCurrentUser(data?.user);
				startButtonAnimation();
			}

		}
	}


	return (
		<motion.div 
		key={i}
		initial={{opacity:0}}
		transition={{ duration: 1 }}
		whileInView={{ opacity:1 }}
		viewport={{ once: true }}
		className={`cursor-pointer hover:md:scale-[109%] hover:scale-[103%] transition-all duration-200 ease-in-out
		hover:shadow-xl hover:shadow-green-500/30 hover:border-[2px] hover:border-[#85A879] 
		border-[1px] p-2 relative rounded-lg border-gray-400 border-solid flex flex-col`}>
			{
				product?.offer > 0 &&
				<div className="absolute top-2 left-0">
					 <div className="relative inline-flex items-center">
				      <div className="bg-[#59b512] text-white py-1 px-1 pl-2 text-sm ">
				        {product?.offer}%
				      </div>
				      <div className="absolute w-0 h-0 top-0 -right-[14px] border-solid 
				      border-t-[14.5px] border-b-[14.5px] border-l-[14.5px] border-t-transparent 
				      border-b-transparent border-l-[#59b512]"/>
				    </div>
				</div>
			}
			<img onClick={()=>{
				setCurrentProductId(product?.productId);
				navigation(`/Product?productId=${product?.productId}`);
				if(document.getElementById('product-image')){
					document.getElementById('product-image').scrollIntoView({
						behavior: "smooth", block: "end", inline: "nearest"
					})
				}
			}} className="rounded-lg w-full aspect-square object-cover" src={product?.images?.[0]} alt=""/>
			<p  onClick={()=>{
				setCurrentProductId(product?.productId);
				navigation(`/Product?productId=${product?.productId}`);
				if(document.getElementById('product-image')){
					document.getElementById('product-image').scrollIntoView({
						behavior: "smooth", block: "end", inline: "nearest"
					})
				}
			}} className="mt-2 px-1 sm:text-md text-lg font-sans text-black">
				{product?.title}
			</p>
			<p  onClick={()=>{
				setCurrentProductId(product?.productId);
				navigation(`/Product?productId=${product?.productId}`);
			}} className="mt-1 px-1 sm:text-sm text-md font-sans text-gray-700">
				Per Unit:- {product?.unit} Kg
			</p>
			<div onClick={()=>{
				setCurrentProductId(product?.productId);
				navigation(`/Product?productId=${product?.productId}`);
				if(document.getElementById('product-image')){
					document.getElementById('product-image').scrollIntoView({
						behavior: "smooth", block: "end", inline: "nearest"
					})
				}
			}} className="flex mt-1 px-1 gap-2 items-center">
				<p className="sm:text-lg text-xl font-sans text-[#59b512]">
					₹ {product?.offer > 0 ? discounted : product?.price}
				</p>
				{
					product?.offer > 0 &&
					<span className="sm:text-md text-lg text-gray-400 line-through">₹ {product?.price}</span>
				}
			</div>
			<div className="w-full px-1 py-2 mt-auto">
				<div className="bg-white max-w-[100%] border-gray-500/40 border-[1px] rounded-lg gap-2 
				flex items-center justify-between px-2 py-1">
					<div 
					onClick={()=>addQuantity()}
					className="p-[2px] rounded-full hover:bg-gray-200/40 transition-all duration-200 ease-in-out">
						<AiOutlinePlus className="select-none h-5 w-5 cursor-pointer text-[#59b512]"/>
					</div>
					<p id="quantity-Coco soap scented" className="text-md text-gray-600 select-none">{quantity}</p>
					<div 
					onClick={()=>minusQuantity()}
					className="p-[2px] rounded-full hover:bg-gray-200/40 transition-all duration-200 ease-in-out">
						<AiOutlineMinus className="select-none h-5 w-5 cursor-pointer text-[#59b512]"/>
					</div>
				</div>
			</div>

			<button onClick={()=>{
				if(!currentUser){
					navigation("/login");
				} else if(!loading){ 
					addToCart()
				}
			}} className="px-4 bg-[#59b512] hover:bg-[#59b512]/80 mt-2 text-white py-1 w-full rounded-lg">
				{
					animationShow ?
					<motion.span 
					initial={{
						opacity:0.3
					}}
					whileInView={{
						opacity:1
					}} 
					transition={{
						duration:0.4
					}}
					viewport={{
						once:true
					}} className="flex justify-center"
					>
						<TiTick className="h-[18px] w-[18px] text-white"/>
					</motion.span>
					:
					loading ? 
					<span className="loader" /> 
					: 
					'Add To Cart'
				}
			</button>
		</motion.div>
	)
}