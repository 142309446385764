import {useState,useEffect} from 'react';
import ReactPlayer from 'react-player'
import { GrYoutube } from "react-icons/gr";
import { HiArrowRight } from "react-icons/hi";

export default function YoutubeChannel({websiteDetails}) {
	const [selected,setSelected] = useState(1);

	return (
		<main className="max-w-6xl mx-auto xl:px-0 mt-5 mb-5 px-5">
			<div className="flex items-center gap-2">
				<h1 className="text-black w-full text-center text-2xl 
				font-semibold flex items-center justify-center gap-3">
					<GrYoutube className="text-red-500 h-8 w-8"/>
					Watch Our Channel
				</h1>


			</div>
			{
				websiteDetails?.youtube?.length > 0 &&
				<div className="grid md:mt-14 mt-7 mb-10 md:grid-cols-3 grid-cols-1 items-center gap-5">
					<div onClick={()=>{setSelected(0)}} 
					className={`transition-all duration-200 ease-in-out 
					${selected === 0 ? 'w-full md:scale-[120%]' : 'md:w-[85%] w-full'} aspect-[16/9]`}>
						<ReactPlayer controls onPlay={()=>{setSelected(0)}} height="100%" width="100%" 
						url={websiteDetails?.youtube[1]} />
					</div>
					<div onClick={()=>setSelected(1)} 
					className={`transition-all duration-200 ease-in-out 
					aspect-[16/9] m-auto ${selected === 1 ? 'w-full md:scale-[120%]' : 'md:w-[85%] w-full'}`}>
						<ReactPlayer controls onPlay={()=>{setSelected(1)}} height="100%" width="100%" 
						url={websiteDetails?.youtube[0]}/>
					</div>
					<div onClick={()=>setSelected(2)} 
					className={`transition-all duration-200 ease-in-out 
					aspect-[16/9]  ml-auto ${selected === 2 ? 'w-full md:scale-[120%]' : 'md:w-[85%] w-full'}`}>
						<ReactPlayer controls onPlay={()=>{setSelected(2)}} height="100%" width="100%" 
						url={websiteDetails?.youtube[2]} />
					</div>
				</div>
			}
			<a href="https://www.youtube.com/@dhanasfood" target="_blank">
				<button className="md:mt-14 mt-7 bg-red-500 text-white px-5 py-2 
				rounded-lg flex items-center gap-3 hover:gap-5 transition-all 
				duration-200 ease-in-out mx-auto">
					More Videos
					<HiArrowRight className="h-5 w-5"/>
				</button>
			</a>
		</main>
	)
}