
import React from 'react';
import './product.css';
import Header from '../components/Header';
import SearchBar from '../components/SearchBar';
import ProductSubImageComponent from '../components/ProductSubImageComponent';
import ProductCardHover from '../components/ProductCardHover';
import {useRecoilState} from 'recoil';
import { FaStar } from "react-icons/fa6";
import {useState,useEffect} from 'react';
import {currentUserState,currentProductIdState} from '../atoms/userAtom';
import {useNavigate,useLocation} from 'react-router-dom';
import {updateUserCart,fetchProduct,updateUserFavoriteProduct,
  fetchSimilarProduct,fetchProducts,login} from '../utils/ApiRoutes';
import axios from 'axios';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import {AiOutlinePlus,AiOutlineMinus} from 'react-icons/ai';
import {TiTick} from 'react-icons/ti';
import '../components/ProductCardHover.css';
import {motion} from 'framer-motion'
import 'react-toastify/dist/ReactToastify.css';
import { FaShareFromSquare } from "react-icons/fa6";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import {  Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';


// import required modules
import { Autoplay, FreeMode, Pagination, Navigation } from 'swiper/modules';

const Product = () => {
  const [currentUser,setCurrentUser] = useRecoilState(currentUserState);
  const [currentProductId,setCurrentProductIdState] = useRecoilState(currentProductIdState);
  const [loading,setLoading] = useState(true);
  const [currentProduct,setCurrentProduct] = useState({});
  const [quantity,setQuantity] = useState(1);
  const [discounted,setDiscounted] = useState(0);
  const [cartLoading,setCartLoading] = useState(false);
  const [animationShow,setAnimationShow] = useState(false);
  const [favorite,setFavorite] = useState(false);
  const [favoriteLoading,setFavoriteLoading] = useState(false);
  const [activeImage,setActiveImage] = useState(0);
  const [similarProducts,setSimilarProducts] = useState([]);
  const [someProducts,setSomeProducts] = useState([]);
  const [trendingProducts,setTrendingProducts] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  
  const toastOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
  }

  const fetchTrendingProducts = async() => {
    const {data} = await axios.post(fetchProducts,{bestSelling:true});
    if(data?.product){
      setTrendingProducts(data?.product);
    }
  }

  const fetchSomeProducts = async() => {
    const {data} = await axios.post(fetchProducts,{bestSelling:false});
    if(data?.product){
      setSomeProducts(data?.product);
    }
  }

  const fetchSimilarProducts = async(product) => {
    const {data} = await axios.post(fetchSimilarProduct,{
      categoryId:product?.categoryId
    })
    if(data.product){
      setSimilarProducts(data.product)
    }
  }

  const fetchProductFunc = async(productId) => {
    setLoading(true)
    const {data} = await axios.post(fetchProduct,{
      productId
    })
    if(data?.product?.length > 0){
      setCurrentProduct(data?.product[0]);
      fetchSimilarProducts(data?.product[0]);
      fetchSomeProducts();
      fetchTrendingProducts();
      setLoading(false);
    }else{
      navigate('/')
      toast.error("Cant Fetch the product",toastOptions)
    }
  }

  useEffect(()=>{
    if(currentProduct?.offer > 0){
      const discountAmount = (currentProduct?.offer / 100) * currentProduct?.price;
        const discountedPrice = currentProduct?.price - discountAmount;
        setDiscounted(Math.round(discountedPrice));
    }
  },[currentProduct])

  useEffect(()=>{
    if(currentProductId || location.search){
      if(currentProductId){
        fetchProductFunc(currentProductId);
      }else if(location.search){
        const params = new URLSearchParams(location.search);
        const param1 = params.get('productId');
        if(param1){
          fetchProductFunc(param1);
        }else{
          navigate('/');
        }
      }
    }else{
      navigate('/')
    }
  },[currentProductId,location.search])

  const addQuantity = () => {
    setQuantity(quantity + 1);
  }

  const minusQuantity = () => {
    if(quantity > 1){
      setQuantity(quantity - 1)
    }
  }

  const startButtonAnimation = () => {
    setCartLoading(false);
    setAnimationShow(true);
    toast.success("Product added to cart",toastOptions);
    setTimeout(()=>{
      setAnimationShow(false);
    },1500)
  }


  const addToCart = async() => {
    setCartLoading(true);
    let cart = [...currentUser?.cart];
    let index = cart?.findIndex((car)=>{
      if(car?.productId === currentProduct?.productId){
        return true
      }
      return false
    })
    if(index > -1){
      let target = cart[index];
      const updatedCartItem = {
        productId:target.productId,
        quantity:target.quantity + quantity
      }
      cart.splice(index,1,updatedCartItem);
      const {data} = await axios.post(updateUserCart,{
        id:currentUser?._id,
        cart
      })

      if(data?.status){
        setCurrentUser(data?.user);
        startButtonAnimation();
      }
    }else{
      let userCart = [...currentUser?.cart,{
        productId:currentProduct?.productId,
        quantity
      }];
      const {data} = await axios.post(updateUserCart,{
        id:currentUser?._id,
        cart:userCart
      })

      if(data?.status){
        setCurrentUser(data?.user);
        startButtonAnimation();
      }

    }
  }

  const handleFavoriteAdd = async() => {
    setFavoriteLoading(true);
    let favProducts = [...currentUser?.favProducts];
    let index = favProducts?.findIndex((car)=>{
      if(car === currentProduct?.productId){
        return true
      }
      return false
    })
    if(index > -1){
      
      favProducts.splice(index,1);
      const {data} = await axios.post(updateUserFavoriteProduct,{
        id:currentUser?._id,
        favProducts
      })
      if(data?.status){
        toast.success("Removed from your favorites",toastOptions)
        setFavorite(false)
        setCurrentUser(data?.user);
      }
    }else{
      let favProducts = [...currentUser?.favProducts,currentProduct?.productId];
      const {data} = await axios.post(updateUserFavoriteProduct,{
        id:currentUser?._id,
        favProducts
      })
      if(data?.status){
        setFavorite(true);
        toast.success("Added to your favorites",toastOptions)
        setCurrentUser(data?.user);
      }

    }
  }

  useEffect(()=>{
    if(currentUser?.favProducts?.length > 0 && currentUser?.favProducts?.includes(currentProduct?.productId)){
      setFavorite(true);
    }else{
      setFavorite(false);
    }
  },[currentProduct]);

  const checkAuth = async() => {
    if(!currentUser && localStorage.getItem("Dhana-Foods-Auth")){
      const localData = localStorage.getItem("Dhana-Foods-Auth");
      const parsedData = JSON.parse(localData);
      const {data} = await axios.post(login,parsedData);
      if(data?.status) {
        setCurrentUser(data?.user);
      }
    }
  }

  useEffect(()=>{checkAuth()},[])

  return (
    <div className="bg-gray-50 overflow-y-auto h-[100vh] w-full">
      <Header />
      <SearchBar />
      <ToastContainer />

      <div className={`fixed left-0 h-full w-full z-[60] ${loading ? 'top-0' : '-top-[100%]'} bg-white`}>
        <section class="dots-container">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </section>
      </div>
      
      <main className="flex sm:flex-row flex-col sm:gap-5 gap-4 sm:p-[20px] px-3 py-5">
        <div className="sm:w-[33%] flex flex-col gap-3 w-full">
          <div id="product-image" className="relative aspect-square w-full">
            {
              currentProduct?.images?.length > 0 &&
              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                interval={2000}
                transitionTime={500}
                showStatus={false}
                swipeable={true} 
                modules={[Autoplay, Pagination, Navigation]}
                className="aspect-square rounded-lg w-full "
              >
                {
                  currentProduct?.images?.map((image,k)=>(
                    <SwiperSlide  key={k}>
                      <ProductSubImageComponent setActiveImage={setActiveImage} 
                      image={image} k={k}/>
                      
                    </SwiperSlide>
                  ))
                }
                
              </Swiper>
            }
            {/*
              <img src={currentProduct?.images?.[0]} 
                  className="rounded-lg w-full aspect-square" 
                  alt="Cannot load image" />
            */}
            {
              currentProduct?.offer > 0 &&
              <div className="absolute z-30 top-2 left-0">
                 <div className="relative inline-flex items-center">
                    <div className="bg-[#59b512] text-white py-1 px-1 pl-2 text-sm ">
                      {currentProduct?.offer}%
                    </div>
                    <div className="absolute w-0 h-0 top-0 sm:-right-[14px] -right-[13.7px] border-solid 
                    border-t-[14px] border-b-[14px] border-l-[14px] border-t-transparent 
                    border-b-transparent border-l-[#59b512]"/>
                  </div>
              </div>
            }
            <div onClick={()=>{
              if(currentUser && currentProduct?.productId){
                handleFavoriteAdd()
              }else{
                navigate('/login')
              }
            }} className="absolute top-2 right-2 
            bg-white/30 z-30 cursor-pointer rounded-lg p-1">
                {
                  favorite ?
                  <AiFillHeart className="h-5 w-5 text-red-500"/>
                  :
                  <AiOutlineHeart className="h-5 w-5 text-gray-200"/>
                }
            </div>
          </div>
          <div className="w-full">
               <Swiper
                slidesPerView={3}
                spaceBetween={10}
                freeMode={true}
                modules={[FreeMode]}
                className="w-full md:h-[130px] sm:h-[150px] h-[120px]"
              >
                {
                  currentProduct?.images?.map((image)=>(
                    <SwiperSlide className={`h-[100px] cursor-pointer w-[100px] aspect-square`} >
                        <img src={image} alt=""
                        className={`aspect-square rounded-lg transition-all duration-200 ease-in-out ${activeImage === image ? 'opacity-1' : 'opacity-[70%]'}`}
                        />
                    </SwiperSlide>                
                  ))  
                }
                
              </Swiper>

          </div>


        </div>
        
        <div className="details w-full">
          <h1 className="flex gap-3" >
            {currentProduct?.title}
            <div className="">
              <div className="hover:bg-gray-200 mt-1 transition-all duration-200 ease-in-out group cursor-pointer p-1 rounded-lg">
                <FaShareFromSquare className="h-5 text-gray-600 group-hover:text-blue-500 transition-all duration-200 ease-in-out w-5"/>
              </div>
            </div>
          </h1>
          {/*<div className="rating">
                      <span>★★★★☆</span>
                      <span>(112)</span>
                    </div>*/}
          <div className="text-2xl my-[10px]">
            <span className="mr-3 text-green-600">₹ {discounted}</span>
            <span className="original-price text-xl">₹ {currentProduct?.price}</span>
          </div>
          <div className="">
            <span className="text-gray-800 text-md">Unit : </span>
            <span className="text-gray-600 text-md">{currentProduct?.unit} kg</span>
          </div>
          <p className="description text-gray-700">
            {currentProduct?.description}
          </p>
          <div className="w-full px-1 py-2 flex sm:flex-row flex-col mt-2 items-center gap-5">
            <div className="flex sm:w-auto w-full justify-between items-center sm:gap-3 gap-5">
              <p className="text-gray-700 text-lg">Quantity</p>
              <div className="sm:w-min w-full h-full">
                <div className="bg-white max-w-[100%] border-gray-500/40 border-[1px] rounded-lg 
                flex items-center gap-9 justify-between px-2 py-1">
                  <div 
                  onClick={()=>addQuantity()}
                  className="p-1 rounded-full hover:bg-gray-200/40 transition-all duration-200 ease-in-out">
                    <AiOutlinePlus className="select-none h-5 w-5 cursor-pointer text-[#59b512]"/>
                  </div>
                  <p id="quantity-Coco soap scented" className="text-md text-gray-600 select-none">{quantity}</p>
                  <div 
                  onClick={()=>minusQuantity()}
                  className="p-1 rounded-full hover:bg-gray-200/40 transition-all duration-200 ease-in-out">
                    <AiOutlineMinus className="select-none h-5 w-5 cursor-pointer text-[#59b512]"/>
                  </div>
                </div>
              </div>
            </div>
            <button onClick={()=>{
              if(!currentUser){
                navigate("/login");
              } else if(!cartLoading){ 
                addToCart()
              }
            }} className="px-4 bg-[#59b512] hover:bg-[#59b512]/80 text-white py-[6px] text-lg w-full rounded-lg">
              {
                animationShow ?
                <motion.span 
                initial={{
                  opacity:0.3
                }}
                whileInView={{
                  opacity:1
                }} 
                transition={{
                  duration:0.4
                }}
                viewport={{
                  once:true
                }} className="flex justify-center"
                >
                  <TiTick className="h-[28px] w-[28px] text-white"/>
                </motion.span>
                :
                loading ? 
                <span className="loader" /> 
                : 
                'Add To Cart'
              }
            </button>

          </div>

          <div className="my-3 bg-gray-400 w-full h-[1px]"/>

          <div className="flex flex-col gap-1">
            {
              currentProduct?.especiallyFor &&
              <p className="text-lg flex font-semibold items-center gap-2 text-black">
                Especially for <span className="text-gray-700 font-normal">{currentProduct?.especiallyFor}</span>
              </p>
            }
            <p className="text-lg flex font-semibold items-center gap-2 text-black">
              Category of <span className="text-gray-700 font-normal">{currentProduct?.category?.map((category)=>`${category}${currentProduct?.category?.length > 1 ? ',' : ''}`)}</span>
            </p>
          </div>


        </div>
        <div className="px-4 hidden lg:block">
          <div className="w-full flex-col">
            {
              similarProducts?.[0]?.productId === currentProduct?.productId ?
              <p className="xl:text-xl text-lg font-semibold text-gray-800">People also bought</p>
              :
              similarProducts?.[1] ?
              <p className="xl:text-xl text-lg font-semibold text-gray-800">People also bought</p>
              :
              ''

            }
            <div className="mt-5">
              {
                similarProducts?.length > 0 &&
                <>
                  {
                    similarProducts[0].productId === currentProduct?.productId ?
                      <ProductCardHover product={similarProducts[0]}
                      i="0" currentUser={currentUser} setCurrentUser={setCurrentUser}
                      />
                    :
                    similarProducts?.[1] ?
                      <ProductCardHover product={similarProducts[1] || {}}
                      i="0" currentUser={currentUser} setCurrentUser={setCurrentUser}
                      />
                    :
                    ''
                  }
                </>
              }
            </div>
          </div>
        </div>

      </main>
      
      <div className="w-full px-4 py-3">
        <h1 className="md:text-2xl text-lg font-semibold text-black">Similar Products</h1>
        <div className="grid xl:grid-cols-5 py-5 lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-2 grid-cols-2 sm:gap-5 gap-3">
          {
            similarProducts?.map((product,i)=>{
              // if(product?.productId !== currentProduct?.productId)
              return(
                <ProductCardHover product={product} key={i} i={i}
                currentUser={currentUser} setCurrentUser={setCurrentUser}
                />
              )}
            )
          }

        </div>
        <div className="my-3 bg-gray-300 w-full h-[1px]"/>
        <h1 className="md:text-2xl text-lg font-semibold text-black">Customer Reviews</h1>
        <div className="flex sm:flex-row flex-col mt-3 gap-9">
          <div className="flex sm:w-[20%] w-full flex-col">
            <p className="text-md font-semibold text-gray-900">
              Public Reviews (0)
            </p>
            <div className="flex mt-2 justify-between">
              <div className="flex items-center gap-2">
                <FaStar className="text-yellow-400 h-5 w-5"/>
                <FaStar className="text-yellow-400 h-5 w-5"/>
                <FaStar className="text-yellow-400 h-5 w-5"/>
                <FaStar className="text-yellow-400 h-5 w-5"/>
                <FaStar className="text-yellow-400 h-5 w-5"/>
              </div>
              <p className="text-gray-800 text-md font-bold">
                0
              </p>
            </div>
            <div className="flex mt-2 justify-between">
              <div className="flex items-center gap-2">
                <FaStar className="text-yellow-400 h-5 w-5"/>
                <FaStar className="text-yellow-400 h-5 w-5"/>
                <FaStar className="text-yellow-400 h-5 w-5"/>
                <FaStar className="text-yellow-400 h-5 w-5"/>
                <FaStar className="text-gray-400 h-5 w-5"/>
              </div>
              <p className="text-gray-800 text-md font-bold">
                0
              </p>
            </div>
            <div className="flex mt-2 justify-between">
              <div className="flex items-center gap-2">
                <FaStar className="text-yellow-400 h-5 w-5"/>
                <FaStar className="text-yellow-400 h-5 w-5"/>
                <FaStar className="text-yellow-400 h-5 w-5"/>
                <FaStar className="text-gray-400 h-5 w-5"/>
                <FaStar className="text-gray-400 h-5 w-5"/>
              </div>
              <p className="text-gray-800 text-md font-bold">
                0
              </p>
            </div>
            <div className="flex mt-2 justify-between">
              <div className="flex items-center gap-2">
                <FaStar className="text-yellow-400 h-5 w-5"/>
                <FaStar className="text-yellow-400 h-5 w-5"/>
                <FaStar className="text-gray-400 h-5 w-5"/>
                <FaStar className="text-gray-400 h-5 w-5"/>
                <FaStar className="text-gray-400 h-5 w-5"/>
              </div>
              <p className="text-gray-800 text-md font-bold">
                0
              </p>
            </div>
            <div className="flex mt-2 justify-between">
              <div className="flex items-center gap-2">
                <FaStar className="text-yellow-400 h-5 w-5"/>
                <FaStar className="text-gray-400 h-5 w-5"/>
                <FaStar className="text-gray-400 h-5 w-5"/>
                <FaStar className="text-gray-400 h-5 w-5"/>
                <FaStar className="text-gray-400 h-5 w-5"/>
              </div>
              <p className="text-gray-800 text-md font-bold">
                0
              </p>
            </div>
            

          </div>

          <div className="sm:w-[60%] w-full mx-auto flex flex-col gap-5">
            <div className="w-full border-[1px] sm:px-4 px-2 py-3 border-gray-400 rounded-lg flex gap-2">
              <img src={currentUser?.image} alt="" className="rounded-lg sm:h-12 h-9 aspect-square"
              />
              <div className="flex flex-col w-full">
                <h2 className="text-lg flex items-center justify-between font-semibold">
                  Thejas Hari
                  <div className="flex items-center gap-2">
                     <FaStar className="text-yellow-400 h-5 w-5"/>
                      <FaStar className="text-yellow-400 h-5 w-5"/>
                      <FaStar className="text-yellow-400 h-5 w-5"/>
                      <FaStar className="text-yellow-400 h-5 w-5"/>
                  </div>
                </h2>
                <div className="sm:my-3 my-2 h-[1px] w-full bg-gray-300"/>
                <p className="text-md text-gray-800">
                  {/*This product was very useful it resolved the problems perfectly. */}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="my-4 bg-gray-300 w-full h-[1px]"/>
        <h1 className="md:text-2xl text-lg font-semibold text-black">Best Selling Products</h1>
        <div className="grid xl:grid-cols-5 py-5 lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-2 grid-cols-2 sm:gap-5 gap-3">
          {
            trendingProducts?.map((product,i)=>{
              // if(product?.productId !== currentProduct?.productId && i < 9)
              return(
                <ProductCardHover product={product} key={i} i={i}
                currentUser={currentUser} setCurrentUser={setCurrentUser}
                />
              )}
            )
          }

        </div>
        <div className="my-4 bg-gray-300 w-full h-[1px]"/>
        <h1 className="md:text-2xl text-lg font-semibold text-black">Explore More</h1>
        <div className="grid xl:grid-cols-5 py-5 lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-2 grid-cols-2 sm:gap-5 gap-3">
          {
            someProducts?.map((product,i)=>{
              // if(product?.productId !== currentProduct?.productId && i < 9)
              return(
                <ProductCardHover product={product} key={i} i={i}
                currentUser={currentUser} setCurrentUser={setCurrentUser}
                />
              )}
            )
          }

        </div>

      </div>




    </div>
  );
};

export default Product;
