
import { IoMdCall } from "react-icons/io";
import {useRecoilState} from 'recoil';
import {currentUserState} from '../atoms/userAtom';
import { HiUser } from "react-icons/hi";
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom'

export default function Header(){
	const [currentUser,setCurrentUser] = useRecoilState(currentUserState);
	const navigate = useNavigate();


	return (
		<header className="flex items-center md:px-10 justify-between px-4 py-2 bg-[#59b512]">
			<p className="text-white font-sans">
				Welcome to Dhana Foods
			</p>
			<div className="flex items-center ">
				<div className={`md:flex hidden border-white mr-7`}>
					<p className="flex items-center border-r-[1px] pr-7 border-white gap-2 text-white" >
						<IoMdCall className="h-4 w-4 " />
						+91 8220373777
					</p>
				</div>
				{
					currentUser &&
					<div className={`md:flex hidden border-white mr-7`}>
						<p className="flex items-center gap-2 text-white" >
							<HiUser className="h-4 w-4 " />
							{currentUser?.name}
						</p>
					</div>
				}
				{
					!currentUser && 
					<button onClick={()=>{
						navigate("/Login")
					}} className="cursor-pointer text-white">
						Login/Signup
					</button>
				}
			</div>
		</header>
	)

} 	