

import {useState,useEffect} from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import {login,verifyOTP,sendOTP,createUser,resetPassword} from '../utils/ApiRoutes';
import PhoneSignInButton from '../components/PhoneSignInButton';
import {useRecoilState} from 'recoil';
import {currentUserState} from '../atoms/userAtom';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Login() {
	const [register,setRegister] = useState(false);
	const [number,setNumber] = useState('');	
	const [password,setPassword] = useState('');	
	const [confirmPassword,setConfirmPassword] = useState('');	
	const [name,setName] = useState('');	
	const [otpCheck,setOtpCheck] = useState(false);
	const [otp,setOtp] = useState('');
	const navigate = useNavigate();
	const [loading,setLoading] = useState(false);
	const [numberVerified,setNumberVerified] = useState(false);
	const [currentUser,setCurrentUser] = useRecoilState(currentUserState);
	const [forgetPassword,setForgetPassword] = useState(false);

	const resetStates = () => {
        setOtpCheck(false);
        setOtp('');
		setNumberVerified(false);
	}


	const toastOptions = {
		position: "top-right",
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "light",
		transition: Bounce,
	}

	const saveToSession = async(number,password) => {
		localStorage.setItem("Dhana-Foods-Auth",JSON.stringify({number,password}));
	};

	const loginUser = async() => {
		if(number?.length > 0 && password?.length > 0){
			setLoading(true);
			const {data} = await axios.post(login,{
				number,password
			})
			if(data?.status) {
				setCurrentUser(data?.user);
				setLoading(false);
				saveToSession();
				toast.success('Login Successful!',toastOptions);
				navigate('/');
				saveToSession(number,password);
			}else{
				setLoading(false);
				toast.error('Number or Password is incorrect!',toastOptions);
			}
		}else{
			toast.error('Number or Password is incorrect!',toastOptions);
		}
	};

	const verifyOTPFunc = async(event) => {
		event.preventDefault();
		const form = document.getElementById('otp-form')
        const inputs = [...form.querySelectorAll('input[type=text]')]
        console.log(inputs)
        const otpByComma = inputs.map((input)=>input.value);
        let otp = '';
        for(let i = 0; i < otpByComma.length; i++){
        	otp += otpByComma[i].toString();
        }
        setLoading(true);

		const {data} = await axios.post(verifyOTP,{
			otp,number,password,name
		})
		if(data.status){
			setCurrentUser(data?.user);
			saveToSession();
			setLoading(false);
			toast.success("Account Verified Successfully",toastOptions);
			navigate('/');
		}else{
			setLoading(false);
			toast.error("OTP is incorrect",toastOptions);
		}
	}

	const checkOTPAndRegister = async() => {
		if(numberVerified){
			setLoading(true);
			const {data} = await axios.post(createUser,{
				number,password,name
			})
			if(data.status){
				setCurrentUser(data?.user);
				saveToSession();
				setLoading(false);
				toast.success("Account Created Successfully",toastOptions);
				navigate('/');
			}else{
				setLoading(false);
				toast.error(data.msg,toastOptions);
			}
		}else{
			toast.error("Phone number is not verified!",toastOptions);
		}
		// setLoading(true);
		// const {data} = await axios.post(sendOTP,{
		// 	number,password
		// });
		// if(data.status){
		// 	setLoading(false);
		// 	setOtpCheck(true);
		// }else{
		// 	setLoading(false);
		// 	toast.error(data?.msg,toastOptions);
		// }
	}

	const handleSubmit = async() => {
		if(register) {
			checkOTPAndRegister()
		} else if(forgetPassword) {
			forgetPasswordFunc();
		} else {
			loginUser();
		}
	}

	const forgetPasswordFunc = async() => {
		if(password !== confirmPassword){
			toast.error("Password not matching",toastOptions);
			return;
		}

		if(password.length < 8){
			toast.error("Password too short",toastOptions);
			return;
		}

		const {data} = await axios.post(resetPassword,{
			number, password
		});
		if(data.status){
			toast.success("Password Reset Successfully",toastOptions);
			resetStates();
			setRegister(false);
			setForgetPassword(false);
		}else{
			toast.error("Cant Reset Password" + data.msg,toastOptions);
			setNumberVerified(false);
		}
	}

	useEffect(()=>{
		if(otpCheck){
			const form = document.getElementById('otp-form')
	        const inputs = [...form.querySelectorAll('input[type=text]')]
	        const submit = form.querySelector('button[type=submit]')

	        const handleKeyDown = (e) => {
	            if (
	                !/^[0-9]{1}$/.test(e.key)
	                && e.key !== 'Backspace'
	                && e.key !== 'Delete'
	                && e.key !== 'Tab'
	                && !e.metaKey
	            ) {
	                e.preventDefault()
	            }

	            if (e.key === 'Delete' || e.key === 'Backspace') {
	                const index = inputs.indexOf(e.target);
	                if (index > 0) {
	                    inputs[index - 1].value = '';
	                    inputs[index - 1].focus();
	                }
	            }
	        }

	        const handleInput = (e) => {
	            const { target } = e
	            const index = inputs.indexOf(target)
	            if (target.value) {
	                if (index < inputs.length - 1) {
	                    inputs[index + 1].focus()
	                } else {
	                    submit.focus()
	                }
	            }
	        }

	        const handleFocus = (e) => {
	            e.target.select()
	        }

	        const handlePaste = (e) => {
	            e.preventDefault()
	            const text = e.clipboardData.getData('text')
	            if (!new RegExp(`^[0-9]{${inputs.length}}$`).test(text)) {
	                return
	            }
	            const digits = text.split('')
	            inputs.forEach((input, index) => input.value = digits[index])
	            submit.focus()
	        }

	        inputs.forEach((input) => {
	            input.addEventListener('input', handleInput)
	            input.addEventListener('keydown', handleKeyDown)
	            input.addEventListener('focus', handleFocus)
	            input.addEventListener('paste', handlePaste)
	        })
		}
	},[otpCheck])

	const checkAuth = async() => {
	    if(!currentUser && localStorage.getItem("Dhana-Foods-Auth")){
	      const localData = localStorage.getItem("Dhana-Foods-Auth");
	      const parsedData = JSON.parse(localData);
	      const {data} = await axios.post(login,parsedData);
	      if(data?.status) {
	        setCurrentUser(data?.user);
	        navigate("/")
	      }
	    }
	}

	useEffect(()=>{checkAuth()},[]);

	return (
		<div className="h-[100vh] w-full flex md:p-0 px-2 items-center justify-center ">
			<div className="flex w-full max-w-sm mx-auto overflow-hidden bg-white rounded-lg shadow-lg dark:bg-gray-800 lg:max-w-4xl">
			    <div className="hidden bg-cover bg-center opacity-[90%] lg:block lg:w-1/2" 
			    style={{backgroundImage: "url('https://ik.imagekit.io/d3kzbpbila/thejashari_yRcKXXh_O')"}}></div>

			    <div className="w-full px-6 py-8 pt-5 md:px-8 lg:w-1/2">
			        <div className={`flex ${register ? 'flex-row items-center' : 'flex-col'}`}>
				        <div className="flex justify-center mx-auto bg-white rounded-full">
				            <img className={`${register ? 'w-12 h-12' : 'w-[70px] h-[70px]'} rounded-full`} src="https://ik.imagekit.io/d3kzbpbila/thejashari_HkNJJQp9gd" alt=""/>
				        </div>

				        <p className="mt-3 text-xl text-center text-gray-600 dark:text-gray-200">
							{
								forgetPassword ? 
								'Forget Password' 
								: 
				            	'Welcome to Dhana Food Store'
							}
				        </p>
			        </div>

			        
					
			        <div className="flex items-center justify-between mt-4">
			            <span className="w-1/5 border-b dark:border-gray-600 lg:w-1/4"></span>

			            <div href="#" className="text-xs text-center text-gray-500 uppercase dark:text-gray-400 hover:underline">
							{forgetPassword ? 
								numberVerified ?
								`Resetting Password for ${number}`
								:
								'Enter Registered Mobile Number to reset password'
							:
							'Login with mobile number'
							}
						</div>

			            <span className="w-1/5 border-b dark:border-gray-400 lg:w-1/4"></span>
			        </div>

			        {
			        	register && !otpCheck &&
			        	<div className="mt-4">
				            <label className="block mb-2 text-md font-medium text-gray-600 dark:text-gray-200" for="LoggingName">User name (will be used for delivery name)</label>
				            <input id="LoggingName" value={name} 
				            onChange={(e)=>setName(e.target.value)}
				            className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-lg dark:bg-gray-800 dark:text-gray-300 
				            dark:border-gray-600 focus:border-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300" 
				            type="text" />
				        </div>
			        }
			        {
			        	otpCheck ?
			        	<>
			        		<header class="mb-8 text-center pt-5">
						        <h1 class="text-2xl font-bold text-white mb-1">Mobile Phone Verification</h1>
						        <p class="text-[15px] text-slate-500">Enter the 6-digit verification code that was sent to your phone number.</p>
						    </header>
			        		<form onSubmit={verifyOTPFunc}  id="otp-form">
						        <div class="flex items-center justify-center gap-3">
						            <input
						                type="text"
						                class="w-[45px] h-[45px] text-center text-xl font-extrabold 
						                text-slate-900 bg-slate-100 border border-transparent hover:border-slate-200 
						                appearance-none rounded md:p-4 p-3 outline-none focus:bg-white focus:border-indigo-400 
						                focus:ring-2 focus:ring-indigo-100"
						                pattern="\d*" maxlength="1" />
						            <input
						                type="text"
						                class="w-[45px] h-[45px] text-center text-xl font-extrabold 
						                text-slate-900 bg-slate-100 border border-transparent hover:border-slate-200 
						                appearance-none rounded md:p-4 p-3 outline-none focus:bg-white focus:border-indigo-400 
						                focus:ring-2 focus:ring-indigo-100"
						                maxlength="1" />
						            <input
						                type="text"
						                class="w-[45px] h-[45px] text-center text-xl font-extrabold 
						                text-slate-900 bg-slate-100 border border-transparent hover:border-slate-200 
						                appearance-none rounded md:p-4 p-3 outline-none focus:bg-white focus:border-indigo-400 
						                focus:ring-2 focus:ring-indigo-100"
						                maxlength="1" />
						            <input
						                type="text"
						                class="w-[45px] h-[45px] text-center text-xl font-extrabold 
						                text-slate-900 bg-slate-100 border border-transparent hover:border-slate-200 
						                appearance-none rounded md:p-4 p-3 outline-none focus:bg-white focus:border-indigo-400 
						                focus:ring-2 focus:ring-indigo-100"
						                maxlength="1" />
						            <input
						                type="text"
						                class="w-[45px] h-[45px] text-center text-xl font-extrabold 
						                text-slate-900 bg-slate-100 border border-transparent hover:border-slate-200 
						                appearance-none rounded md:p-4 p-3 outline-none focus:bg-white focus:border-indigo-400 
						                focus:ring-2 focus:ring-indigo-100"
						                maxlength="1" />
						            <input
						                type="text"
						                class="w-[45px] h-[45px] text-center text-xl font-extrabold 
						                text-slate-900 bg-slate-100 border border-transparent hover:border-slate-200 
						                appearance-none rounded md:p-4 p-3 outline-none focus:bg-white focus:border-indigo-400 
						                focus:ring-2 focus:ring-indigo-100"
						                maxlength="1" />
						        </div>
						        <div class="max-w-[260px] mx-auto mt-10">
						            <button type="submit"
					                class="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-indigo-500 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-indigo-600 focus:outline-none focus:ring focus:ring-indigo-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 transition-colors duration-150">Verify
					                Account</button>
						        </div>
						    </form>
			        	</>
			        	:
			        	<>
							{
								(!forgetPassword || !numberVerified) &&
								<div className="mt-4">
									<label className="block mb-2 text-md font-medium text-gray-600 dark:text-gray-200" for="LoggingMobileNumber">Mobile Number</label>
									<div className="flex items-center gap-1">
										<input id="LoggingMobileNumber" value={number} onChange={(e)=>setNumber(e.target.value)}
										className="pe_phone_number block w-full px-4 py-2 text-gray-700 bg-white border rounded-lg dark:bg-gray-800 dark:text-gray-300 
										dark:border-gray-600 focus:border-blue-400 focus:ring-opacity-40 
										dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300" 
										type="tel" />
									
									</div>
								</div>
							}

							{
								!forgetPassword &&
								<div className="mt-4">
									<div className="flex justify-between">
										<label className="block mb-2 text-md font-medium text-gray-600 dark:text-gray-200" for="loggingPassword">Password</label>
										{
											!register && !forgetPassword &&
											<div onClick={()=>{
												setForgetPassword(true);
											}} href="#" className="text-xs cursor-pointer text-gray-500 dark:text-gray-300 hover:underline">Forget Password?</div>
										}
									</div>

									<input id="loggingPassword" value={password} onChange={(e)=>setPassword(e.target.value)}
									className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-lg dark:bg-gray-800 dark:text-gray-300 
									dark:border-gray-600 focus:border-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none 
									focus:ring focus:ring-blue-300" type="password" />
								</div>
							}

							{
								forgetPassword && numberVerified &&

								<div className="mt-4">
									<label className="block mb-2 text-md font-medium text-gray-600 dark:text-gray-200" for="LoggingMobileNumber">New Password</label>
									<input id="loggingPassword" value={password} onChange={(e)=>setPassword(e.target.value)}
									className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-lg dark:bg-gray-800 dark:text-gray-300 
									dark:border-gray-600 focus:border-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none 
									focus:ring focus:ring-blue-300" type="password" />
									<label className="block mb-2 mt-5 text-md font-medium text-gray-600 dark:text-gray-200" for="LoggingMobileNumber">Confirm Password</label>
									<input id="loggingPassword" value={confirmPassword} onChange={(e)=>setConfirmPassword(e.target.value)}
									className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-lg dark:bg-gray-800 dark:text-gray-300 
									dark:border-gray-600 focus:border-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none 
									focus:ring focus:ring-blue-300" type="password" />
								</div>
							}
			        	</>
			        }

			        {
			        	!register && !forgetPassword &&
				        <div className="mt-6">
				            
				            <button onClick={()=>{if(!loading) handleSubmit()}} 
				            className={`w-full px-6 py-3 text-sm font-medium tracking-wide text-white capitalize 
				            transition-all duration-300 transform bg-gray-800 rounded-lg 
				            hover:bg-gray-700 focus:outline-none focus:ring focus:ring-gray-300 
				            focus:ring-opacity-50 ${loading ? 'opacity-[40%]' : 'opacity-1'} `}>
				                Sign {register ? 'Up' : 'In'}
				            </button>
				           
				        </div>
			        }
			        {
			        	register && numberVerified &&
				        <div className="mt-6">
				            
				            <button onClick={()=>{if(!loading) handleSubmit()}} 
				            className={`w-full px-6 py-3 text-sm font-medium tracking-wide text-white capitalize 
				            transition-all duration-300 transform bg-gray-800 rounded-lg 
				            hover:bg-gray-700 focus:outline-none focus:ring focus:ring-gray-300 
				            focus:ring-opacity-50 ${loading ? 'opacity-[40%]' : 'opacity-1'} `}>
				                Sign {register ? 'Up' : 'In'}
				            </button>
				           
				        </div>
			        }
					{
			        	forgetPassword && numberVerified &&
				        <div className="mt-6">
				            
				            <button onClick={()=>{if(!loading) handleSubmit()}} 
				            className={`w-full px-6 py-3 text-sm font-medium tracking-wide text-white capitalize 
				            transition-all duration-300 transform bg-gray-800 rounded-lg 
				            hover:bg-gray-700 focus:outline-none focus:ring focus:ring-gray-300 
				            focus:ring-opacity-50 ${loading ? 'opacity-[40%]' : 'opacity-1'} `}>
				                Reset Password
				            </button>
				           
				        </div>
			        }
			        {
			        	((register && !numberVerified) || (forgetPassword && !numberVerified)) &&
			        	<div className="flex items-center cursor-pointer justify-center px-4 py-1 mt-2">
			        		<PhoneSignInButton setNumberVerified={setNumberVerified} toast={toast}
			        		toastOptions={toastOptions} />
			        	</div>
			        }

			        {
			        	!otpCheck && !forgetPassword &&
				        <div className="flex items-center justify-between mt-4">
				            <span className="w-1/10 border-b dark:border-gray-600 md:w-1/7"></span>

				            <div href="#" onClick={()=>{
								setRegister(!register); 
								resetStates();
							}}
				            className="text-sm text-gray-500 uppercase 
				            cursor-pointer dark:text-gray-400 hover:underline">{register ? 'Already Having' : 'Dont have' } an account? <span className="text-blue-500" >{register ?  'Login' : 'Create an account'}</span></div>

				            <span className="w-1/10 border-b dark:border-gray-600 md:w-1/7"></span>
				        </div>
			        }

					{
						forgetPassword &&
						<div className="flex items-center justify-between mt-4">
				            <span className="w-1/10 border-b dark:border-gray-600 md:w-1/7"></span>

				            <div href="#" onClick={()=>{
								setForgetPassword(false);
								resetStates();
							}}
				            className="text-sm text-gray-500 uppercase 
				            cursor-pointer dark:text-gray-400 hover:underline">Login or Create an Account</div>

				            <span className="w-1/10 border-b dark:border-gray-600 md:w-1/7"></span>
				        </div>
					}
			    </div>
			</div>
			<ToastContainer />
		</div>
	)
}