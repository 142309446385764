
import {useState,useEffect} from 'react';

export default function CategoryCard({getTotalCategoryProducts,
	axios,category,PiShapes,j,setSelectedCategories,navigate}) {

	const [productCount,setProductCount] = useState(0);

	const fetchProductCount = async() => {
		const {data} = await axios.post(getTotalCategoryProducts,{
			categoryId:category?.categoryId
		});
		if(data?.status){
			setProductCount(Number(data?.productCount));
		}else{
			setProductCount(0);
		}
	}

	useEffect(()=>{
		if(category?.categoryId){
			fetchProductCount()
		}
	},[category])
	

	return(
		<div key={j} onClick={()=>{
			setSelectedCategories([category?.categoryId]);
			navigate("/Products");
		}} className="flex border-[4px] border-gray-300/30 hover:border-gray-300/80 
		cursor-pointer transition-all duration-200 ease-in-out rounded-lg 
		flex-col p-2 bg-gray-50/50 group hover:bg-gray-50">
			<div className="overflow-hidden rounded-lg">
				<img src={category?.image} alt=""
				className="w-full group-hover:scale-[110%] transition-all duration-200 ease-in-out 
				rounded-lg object-cover aspect-square"
				/>
			</div>
			<p className="text-gray-800 mt-2 text-md">
				{category?.name}
			</p>
			<p className="text-gray-800 flex mt-2 items-center gap-1 text-sm">
				<PiShapes className="h-4 w-4 text-gray-800" />
				{productCount} products
			</p>
		</div>
	)
}