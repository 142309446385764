import { useState, useEffect } from "react";
import { RxCross2 } from "react-icons/rx";

const WebUpdateStatus = ({ websiteDetails }) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // Toggle body scroll based on modal visibility
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showModal]);

  const handleClose = () => {
    setShowModal(false);
  };

  if (!showModal) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-[999] flex items-center justify-center p-4">
      <div className="bg-white rounded-lg  w-full sm:w-[800px] p-6 relative flex justify-center items-center max-sm:flex-col">
        {/* img div here */}
        <div className="">
          <button
            onClick={handleClose}
            className="absolute sm:-top-3 sm:-right-3 -right-2 -top-2 bg-white rounded-full p-1 shadow-lg hover:scale-105 transition-all"
          >
            <RxCross2 className="sm:h-6 sm:w-6 h-4 w-4 text-gray-600" />
          </button>
          <img
            src="https://ik.imagekit.io/d3kzbpbila/thejashari_8KFvuHfZ6"
            alt="illustration Image"
            className="sm:w-[300px] sm:h-[300px] w-[200px] h-[200px]"
          ></img>
        </div>

        {/* Content div here */}

        <div className="text-center">
          <h3 className="text-xl font-bold text-gray-800 mb-6">
            Website is Under Maintenance
          </h3>
          <div className="w-full sm:w-[500px]">
            <p className="text-gray-600 text-sm leading-relaxed">
              We're currently upgrading our ordering system to serve you better.
              While we complete this maintenance, please use our direct WhatsApp
              ordering for immediate service.
            </p>
            <p className="text-gray-600  leading-relaxed mt-5 font-semibold">
              Order via Whatsapp Now +91 9384332099
            </p>
          </div>
        </div>
      </div>
      {/* {" img and content parent div end"} */}
    </div> // main div end
  );
};

export default WebUpdateStatus;

{
  /* {websiteDetails?.image?.url && (
          <img
            src={websiteDetails.image.url}
            alt="Offer"
            className="w-full h-48 object-cover rounded-lg mb-4"
          />
        )} */
}
