export const host = 'https://dhanafoods-server.vercel.app';
// 'http://localhost:3333';

export const login = host + '/api/user/login';
export const verifyOTP = host + '/api/user/verifyOTP'
export const sendOTP = host + '/api/user/sendOTP'
export const updateUserCart = host + '/api/user/updateUserCart';
export const resetPassword = host + '/api/user/resetPassword';
export const getWebsiteRoute = host + '/api/site/getWebsite';
export const fetchProducts = host + '/api/site/fetchProducts';
export const fetchCategories = host + '/api/site/fetchCategories';
export const getTotalCategoryProducts = host + '/api/site/getTotalCategoryProducts';
export const fetchProduct = host + '/api/site/fetchProduct';
export const updateUserAddress = host + '/api/site/updateUserAddress';
export const updateSelectedAddress = host + '/api/site/updateSelectedAddress';
export const fetchOrdersRoute = host + '/api/site/fetchOrders';
export const updateUserFavoriteProduct = host + '/api/site/updateUserFavoriteProduct';
export const fetchSimilarProduct = host + '/api/site/fetchSimilarProduct';
export const uploadImageRoute = host + '/uploadImage';
export const getProducts = host + '/api/site/getProducts';
export const updateUserDetails = host + '/api/site/updateUserDetails';
export const createUser = host + '/api/site/createUser';