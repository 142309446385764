
import { GrAchievement } from "react-icons/gr";

import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import './achievements.css';
// import required modules
import { EffectCoverflow, Pagination, Autoplay } from 'swiper/modules';

export default function Achievements({websiteDetails}) {
	


	return (
		<div className="max-w-6xl mx-auto xl:px-0 mt-14 mb-5 px-2">
			<div className="flex items-center gap-2">
				<h1 className="text-black w-full text-center md:text-2xl text-xl 
				font-semibold flex items-center justify-center gap-3">
					<GrAchievement className="text-red-500 h-8 w-8"/>
					Milestones & Achievements
				</h1>


			</div>
			{
	      		websiteDetails?.milestones?.length > 0 &&
	      		<Swiper
		        effect={'coverflow'}
		        grabCursor={true}
		        centeredSlides={true}
		        slidesPerView={'auto'}
		        coverflowEffect={{
		          rotate: 50,
		          stretch: 0,
		          depth: 100,
		          modifier: 1,
		          slideShadows: true,
		        }}
		        autoplay={{
		          delay: 3000,
		          disableOnInteraction: false,
		        }}
		        pagination={true}
		        modules={[EffectCoverflow, Autoplay]}
		        className="mySwiper mt-7"
			    >
				    {
			      		websiteDetails?.milestones?.map((image,k)=>(
					        <SwiperSlide key={k}>
					          <img className="object-cover" src={image} />
					        </SwiperSlide>
			      		))
				    }
			    </Swiper>
		    }
		</div>
	)
}