"use client"

import { atom } from 'recoil';

export const currentUserState = atom({
	key:"currentUserState",
	default:''
})

export const currentProductIdState = atom({
	key:"currentProductIdState",	
	default:''
})

export const openSideBarState = atom({
	key:"openSideBarState",
	default:false
})

export const selectedCategoriesState = atom({
	key:"selectedCategoriesState",
	default:[]
})

export const searchQueryState = atom({
	key:"searchQueryState",
	default:''
})